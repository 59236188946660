import { CATALOG_MAIN_META } from '../../meta/main/catalog'

export default [
  {
    path: 'catalog',
    name: 'home-catalog',
    component: () => import('@/views/main/catalog/Catalog.vue'),
    redirect: { name: 'home-main-catalog-list' },
    children: [
      {
        path: '',
        name: 'home-main-catalog-list',
        component: () => import('@/views/main/catalog/list/CatalogList.vue'),
        layout: 'Catalog List',
        redirect: { name: 'home-main-catalog-list-products' },
        children: [
          {
            path: 'products',
            name: 'home-main-catalog-list-products',
            component: () => import('@/views/main/catalog/list/ProductsList.vue'),
            layout: 'Container List',
            meta: CATALOG_MAIN_META,
          },
          {
            path: 'stock',
            name: 'home-main-catalog-list-stocks',
            component: () => import('@/views/main/catalog/list/StocksList.vue'),
            layout: 'Container List',
            meta: CATALOG_MAIN_META,
          },
          {
            path: 'non-stock',
            name: 'home-main-catalog-list-non-stocks',
            component: () => import('@/views/main/catalog/list/NonStocksList.vue'),
            layout: 'Container List',
            meta: CATALOG_MAIN_META,
          },
          {
            path: 'kits',
            name: 'home-main-catalog-list-kits',
            component: () => import('@/views/main/catalog/Kits.vue'),
            redirect: { name: 'home-kits-list' },
            layout: 'Kits List',
            children: [
              {
                path: 'trash',
                name: 'home-main-catalog-trash-list-kits',
                component: () => import('@/views/main/catalog/list/KitsTrashList.vue'),
                layout: 'Kits Trash List',
                meta: CATALOG_MAIN_META,
              },
              {
                path: '',
                name: 'home-kits-list',
                component: () => import('@/views/main/catalog/list/KitsList.vue'),
                layout: 'Kits List',
                meta: CATALOG_MAIN_META,
              },
              {
                path: 'create',
                name: 'home-kits-create',
                component: () => import('@/views/main/catalog/create/KitsCreate.vue'),
                layout: 'Add Kits',
                meta: CATALOG_MAIN_META,
              },
              {
                path: ':id',
                name: 'home-kits-view',
                component: () => import('@/views/main/catalog/update/KitsUpdate.vue'),
                layout: 'Kits View',
                meta: CATALOG_MAIN_META,
              },
            ],
          },
        ],
      },
      {
        path: 'stock/trash',
        name: 'home-main-catalog-trash-list-stocks',
        component: () => import('@/views/main/catalog/list/StocksTrashList.vue'),
        layout: 'Stock Trash List',
        meta: CATALOG_MAIN_META,
      },
      {
        path: 'non-stock/trash',
        name: 'home-main-catalog-trash-list-non-stocks',
        component: () => import('@/views/main/catalog/list/NonStocksTrashList.vue'),
        layout: 'Non-stock Trash List',
        meta: CATALOG_MAIN_META,
      },
      {
        path: 'create',
        name: 'home-catalog-create',
        component: () => import('@/views/main/catalog/create/CatalogCreate.vue'),
        layout: 'Add Catalog',
        meta: CATALOG_MAIN_META,
      },
      {
        path: ':id',
        name: 'home-catalog-view',
        component: () => import('@/views/main/catalog/view/CatalogView.vue'),
        layout: 'Catalog View',
        redirect: { name: 'home-catalog-general-info' },
        children: [
          {
            path: 'general-info',
            name: 'home-catalog-general-info',
            component: () => import('@/views/main/catalog/view/general/General.vue'),
            layout: 'General info',
            meta: CATALOG_MAIN_META,
          },
          {
            path: 'rates',
            name: 'home-catalog-rates',
            component: () => import('@/views/main/catalog/view/rates/Rates.vue'),
            layout: 'Catalog rate',
            meta: CATALOG_MAIN_META,
          },
          {
            path: 'phy-properties',
            name: 'home-catalog-phy-properties',
            component: () => import('@/views/main/catalog/view/phy-properties/PhyProperties.vue'),
            layout: 'Home Catalog',
            redirect: { name: 'home-catalog-phy-properties-view' },
            children: [
              {
                path: '',
                name: 'home-catalog-phy-properties-view',
                component: () => import('@/views/main/catalog/view/phy-properties/PhyPropertiesView.vue'),
                layout: 'Home Phy-properties Catalog',
                meta: CATALOG_MAIN_META,
              },
            ],
          },
          {
            path: 'components',
            name: 'home-catalog-components',
            component: () => import('@/views/main/catalog/view/components/Components.vue'),
            layout: 'Home Catalog',
            redirect: { name: 'home-catalog-components-list' },
            children: [
              {
                path: '',
                name: 'home-catalog-components-list',
                component: () => import('@/views/main/catalog/view/components/update/UpdateComponent.vue'),
                layout: 'Catalog Components List',
                meta: CATALOG_MAIN_META,
              },
            ],
          },
          {
            path: 'attachments',
            name: 'home-catalog-attachments',
            component: () => import('@/views/main/catalog/view/attachments/Attachments.vue'),
            layout: 'Attachments',
            redirect: { name: 'home-catalog-attachment-list' },
            children: [
              {
                path: '',
                name: 'home-catalog-attachment-list',
                component: () => import('@/views/main/catalog/view/attachments/list/AttachmentList.vue'),
                layout: 'Catalog Attachments List',
                meta: CATALOG_MAIN_META,
              },
              {
                path: 'trash',
                name: 'home-catalog-attachment-trash',
                component: () => import('@/views/main/catalog/view/attachments/list/AttachmentTrashCan.vue'),
                layout: 'Catalog Attachments Trash',
                meta: CATALOG_MAIN_META,
              },
            ],
          },
          {
            path: 'procurement',
            name: 'home-catalog-procurement',
            component: () => import('@/views/main/catalog/view/procurement/Procurement.vue'),
            layout: 'Home Catalog',
            meta: CATALOG_MAIN_META,
          },
          {
            path: 'substitutes',
            name: 'home-catalog-substitutes',
            component: () => import('@/views/main/catalog/view/substitutes/Substitutes.vue'),
            layout: 'Home Catalog',
            redirect: { name: 'home-catalog-substitutes-list' },
            children: [
              {
                path: 'substitutes-list',
                name: 'home-catalog-substitutes-list',
                component: () => import('@/views/main/catalog/view/substitutes/list/SubstitutesList.vue'),
                layout: 'Home Catalog Substitutes List',
                meta: CATALOG_MAIN_META,
              },
            ],
          },
          {
            path: 'assets',
            name: 'home-catalog-assets',
            component: () => import('@/views/main/catalog/view/assets/Assets.vue'),
            layout: 'Home Catalog',
            meta: CATALOG_MAIN_META,
          },
          {
            path: 'audit',
            name: 'home-catalog-audit',
            component: () => import('@/views/main/catalog/view/audit/Audit.vue'),
            layout: 'Home Catalog Audit',
            redirect: { name: 'home-catalog-audit-list' },
            children: [
              {
                path: '',
                name: 'home-catalog-audit-list',
                component: () => import('@/views/main/catalog/view/audit/components/AuditTable.vue'),
                layout: 'Home Catalog Audit',
                meta: CATALOG_MAIN_META,
              },
            ],
          },
          {
            path: 'reports',
            name: 'home-catalog-reports',
            component: () => import('@/views/main/catalog/view/reports/Reports.vue'),
            layout: 'Home Catalog',
            meta: CATALOG_MAIN_META,
          },
        ],
      },
    ],
  },
]
