import { extend, localize } from 'vee-validate'
import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  max as rule_max,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length,
  min_value as rule_min_value,
  max_value as rule_max_value,
  numeric as rule_numeric,

} from 'vee-validate/dist/rules'

import en from './locale/en.json'

// eslint-disable-next-line object-curly-newline
import {
  validatorPositive,
  validatorUrlValidator,
  validatorPassword,
  validatorCreditCard,
  validatorFileSize,
  validatorPhoneNumber, validatorRequired,
} from './validators'

export const required = extend('required', rule_required, 'sdfsdf')

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)

export const max = extend('max', rule_max)

export const minValue = extend('min_value', rule_min_value)

export const maxValue = extend('max_value', rule_max_value)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const numeric = extend('numeric', rule_numeric)

export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!',
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!',
})

export const password = extend('password', {
  validate: validatorPassword,
  message: 'Your {_field_} must contain at least one uppercase, one lowercase',
})

export const phoneNumber = extend('phone', {
  validate: validatorPhoneNumber,
  message: 'Your {_field_} must be like +(xx)xxxxxxx',
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL is invalid',
})

export const fileSize = extend('file-size', {
  validate: validatorFileSize,
  params: ['size'],
  message: 'The maximum file size that can be sent {size} MB',
})

export const phoneIsValid = extend('phone-is-valid', {
  validate: () => (false),
  message: 'This {_field_} is not valid',
})

export const simpleRequired = extend('simple-required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1,
    }
  },
  computesRequired: true,
  message: ' Required',
})

localize({
  en: {
    messages: en.messages,
  },
})
// Install English and Arabic localizations.
