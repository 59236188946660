import axios from '@/libs/axios'
import crudActions from '@/libs/storeConstActions'

const endpoint = () => 'users/me/current-warehouse'
const notificationEndpoint = () => 'notifications'
const Actions = crudActions(endpoint)

const updateWarehouseLocation = (ctx, data) => axios.put(endpoint(), data)

const getWarehousesList = ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/settings/warehouses')
    .then(response => {
      commit('WAREHOUSE_LOCATION', response.data.data.data)
      commit('SET_DATE_TIME_OF_WAREHOUSE', {
        dateGmt: response.headers.date,
        gmtOffSet: response.headers.gmt_offset,
      })
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})
const getNotificationList = (ctx, data) => axios.get(`${notificationEndpoint()}`, data)
const updateNotificationStatus = (ctx, data) => axios.patch(`${notificationEndpoint()}`, data)

const getMe = (ctx, data) => axios.get('users/me/permissions')

export default {
  ...Actions,
  updateWarehouseLocation,
  getWarehousesList,
  getMe,
  getNotificationList,
  updateNotificationStatus,
}
