import { REPORTS_MAIN_META } from '../../meta/main/reports'

export default [
  {
    path: 'reports',
    name: 'home-reports',
    component: () => import('@/views/main/reports/Reports.vue'),
    redirect: { name: 'home-reports-list' },
    children: [
      {
        path: 'reports',
        name: 'home-reports-list',
        component: () => import('@/views/main/reports/reports-list/ReportsList.vue'),
        layout: 'Home Reports',
        meta: REPORTS_MAIN_META,
      },
    ],
  },
]
