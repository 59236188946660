import Vue from 'vue'
import Vuex from 'vuex'

// Modules

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
// eslint-disable-next-line import/no-cycle
import listModule from './lists'
import cloneData from './clone-data'
import horizontalMenu from './horizontal-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    listModule,
    cloneData,
    horizontalMenu,

  },
  strict: process.env.DEV,
})
