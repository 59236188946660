// eslint-disable-next-line import/named
import { GENERAL_REPORTS_META } from '../../meta/settings/reports'

export default [
  {
    path: 'reports',
    name: 'settings-reports',
    component: () => import('@/views/settings/reports/Reports.vue'),
    redirect: { name: 'settings-reports-list' },
    children: [
      {
        path: 'reports-list',
        name: 'settings-reports-list',
        component: () => import('@/views/settings/reports/reports-list/ReportsList.vue'),
        layout: 'Reports',
        meta: GENERAL_REPORTS_META,
      },
    ],
  },
]
