import home from './home/home'
import catalog from './catalog/catalog'
import customers from './customers/customers'
import orders from './orders/orders'
import warehouse from './warehouse/warehouse'
import inventory from './inventory/inventory'
import reports from './reports/reports'

export default [
  {
    path: '/',
    name: 'main-page',
    component: () => import('@/views/main/Main.vue'),
    redirect: { name: 'home' },
    children: [
      ...home,
      ...catalog,
      ...customers,
      ...orders,
      ...warehouse,
      ...inventory,
      ...reports,
    ],
  },
]
