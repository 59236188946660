import Vue from 'vue'

Vue.filter('phone', phone => {
  // console.log(phone.replace('-', ''))
  const clearPhoneNumbers = Array.from(phone).filter(item => item !== '-').join('')
  const isNumber = clearPhoneNumbers.match(/^[0-9]+$/) != null
  if (isNumber) {
    return clearPhoneNumbers.replace(/\D/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3')
  }
  return phone
})
