<template>
  <div>
    <b-table
      ref="selectableTable"
      class="mt-2"
      :fields="fields"
      :select-mode="selectMode"
      responsive="sm"
      v-bind="getTableProps()"
    />
    <div
      v-for="count in 3"
      :key="count"
    >
      <b-skeleton
        style="margin-top: 2px"
        height="40px"
        width="100%"
        class="mb-0"
      />
    </div>
  </div>
</template>
<script>
import {
  BSkeleton, BTable,
} from 'bootstrap-vue'

export default {
  name: 'BTableSkeleton',
  components: {
    BSkeleton,
    BTable,
  },
  props: {
    fields: {
      type: [Array, Object],
      required: true,
    },
  },
  data() {
    return {
      // fields: ['selected', 'isActive', 'age', 'first_name', 'last_name'],
      items: [
        {
          isActive: true, age: 40, first_name: 'Dickerson', last_name: 'Macdonald',
        },
        {
          isActive: false, age: 21, first_name: 'Larsen', last_name: 'Shaw',
        },
        {
          isActive: false, age: 89, first_name: 'Geneva', last_name: 'Wilson',
        },
        {
          isActive: true, age: 38, first_name: 'Jami', last_name: 'Carney',
        },
      ],
      selectMode: 'multi',
      selected: [],
      collectionOfListArr: [],
    }
  },
  methods: {
    getTableProps() {
      const defaultTableProps = {
        ref: `${this.moduleName}Table`,
        items: Array.from(this.collectionOfListArr),
        responsive: true,
        fields: this.tableColumns,
        'primary-key': 'id',
        'show-empty': this.showEmptyListTitle,
        'empty-text': this.$t('List Empty'),
        class: 'position-relative',
        striped: true,
        'foot-clone': this.isFooter,
      }
      if (this.rowClicked) {
        defaultTableProps.hover = true
      }
      return {
        ...defaultTableProps,
        ...this.tableProps,
      }
    },
  },
}
</script>
