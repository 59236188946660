import warehouse from './warehouse'
import { GENERAL_FULFILLMENT_META } from '../../meta/settings/fulfillment'
import {GENERAL_INVENTORY_META} from "@/router/meta/settings/inventory";

export default [
  {
    path: 'fulfillment',
    name: 'settings-fulfillment',
    component: () => import('@/views/settings/fulfillment/Fulfillment.vue'),
    redirect: { name: 'settings-fulfillment-warehouses' },
    children: [
      warehouse,
      {
        path: 'operation-hours',
        name: 'settings-fulfillment-operation-hours',
        component: () => import('@/views/settings/fulfillment/operation-hours/OperationHours.vue'),
        layout: 'Fulfillment',
        meta: GENERAL_FULFILLMENT_META,
      },
      {
        path: 'processes',
        name: 'settings-fulfillment-processes',
        component: () => import('@/views/settings/fulfillment/processes/Processes.vue'),
        redirect: { name: 'settings-fulfillment-processes-update' },
        children: [
          {
            path: '',
            name: 'settings-fulfillment-processes-update',
            component: () => import('@/views/settings/fulfillment/processes/components/ProcessesForm.vue'),
            layout: 'Fulfillment',
            meta: GENERAL_FULFILLMENT_META,
          },
        ],
      },
      {
        path: 'dispatch',
        name: 'settings-fulfillment-dispatch',
        component: () => import('@/views/settings/fulfillment/dispatch/Dispatch.vue'),
        layout: 'Fulfillment',
        redirect: { name: 'settings-fulfillment-dispatch-list' },
        meta: GENERAL_FULFILLMENT_META,
        children: [
          {
            path: '',
            name: 'settings-fulfillment-dispatch-list',
            component: () => import('@/views/settings/fulfillment/dispatch/list/DispatchList.vue'),
            layout: 'Fulfillment Dispatch List',
            meta: GENERAL_FULFILLMENT_META,
          },
          {
            path: 'trash',
            name: 'settings-fulfillment-dispatch-trash-list',
            component: () => import('@/views/settings/fulfillment/dispatch/list/DispatchTrashList.vue'),
            layout: 'Fulfillment Dispatch Trash List',
            meta: GENERAL_FULFILLMENT_META,
          },
          {
            path: 'create',
            name: 'settings-fulfillment-dispatch-create',
            component: () => import('@/views/settings/fulfillment/dispatch/create/DispatchCreate.vue'),
            layout: 'Fulfillment Dispatch Create',
            meta: GENERAL_FULFILLMENT_META,
          },
          {
            path: ':id/update',
            name: 'settings-fulfillment-dispatch-update',
            component: () => import('@/views/settings/fulfillment/dispatch/update/DispatchUpdate.vue'),
            layout: 'Fulfillment Dispatch Update',
            meta: GENERAL_FULFILLMENT_META,
          },
        ],
      },
      {
        path: 'labels',
        name: 'settings-fulfillment-labels',
        component: () => import('@/views/settings/fulfillment/labels/Labels.vue'),
        layout: 'Fulfillment Labels',
        redirect: { name: 'settings-fulfillment-labels-form' },
        children: [
          {
            path: '',
            name: 'settings-fulfillment-labels-form',
            component: () => import('@/views/settings/fulfillment/labels/components/LabelsFrom.vue'),
            layout: 'Fulfillment Labels Form',
            meta: GENERAL_FULFILLMENT_META,
          },
        ],
      },
      {
        path: 'templates',
        name: 'settings-fulfillment-templates',
        component: () => import('@/views/settings/fulfillment/templates/Templates.vue'),
        layout: 'Fulfillment Labels',
        redirect: { name: 'settings-fulfillment-templates-form' },
        children: [
          {
            path: '',
            name: 'settings-fulfillment-templates-form',
            component: () => import('@/views/settings/fulfillment/templates/components/TemplatesForm.vue'),
            layout: 'Fulfillment Templates Form',
            meta: GENERAL_FULFILLMENT_META,
          },
        ],
      },
      {
        path: 'rules',
        name: 'settings-fulfillment-rules',
        component: () => import('@/views/settings/fulfillment/rules/Rules.vue'),
        layout: 'Fulfillment Rules',
        redirect: { name: 'settings-fulfillment-rules-form' },
        children: [
          {
            path: '',
            name: 'settings-fulfillment-rules-form',
            component: () => import('@/views/settings/fulfillment/rules/components/RulesForm.vue'),
            layout: 'Fulfillment Rules Form',
            meta: GENERAL_FULFILLMENT_META,
          },
        ],
      },
      //{
      //  path: 'couriers',
      //  name: 'settings-fulfillment-couriers',
      //  component: () => import('@/views/settings/fulfillment/couriers/Couriers.vue'),
      //  layout: 'Fulfillment',
      //  meta: GENERAL_FULFILLMENT_META,
      //},
    ],
  },
]
