import { GENERAL_META } from '../../meta/settings/general'

export default {
  path: 'taxes',
  name: 'settings-taxes',
  component: () => import('@/views/settings/general/taxes/Taxes.vue'),
  layout: 'Taxes',
  redirect: { name: 'settings-taxes-list' },
  children: [
    {
      path: '',
      name: 'settings-taxes-list',
      component: () => import('@/views/settings/general/taxes/list/TaxesList.vue'),
      layout: 'Taxes List',
      meta: GENERAL_META,
    },
    {
      path: 'create',
      name: 'settings-taxes-create',
      component: () => import('@/views/settings/general/taxes/create/TaxesCreate.vue'),
      layout: 'Taxes Create',
      meta: GENERAL_META,
    },
    {
      path: ':id/update',
      name: 'settings-taxes-update',
      component: () => import('@/views/settings/general/taxes/update/TaxesUpdate.vue'),
      layout: 'Taxes Update',
      meta: GENERAL_META,
    },
    {
      path: 'trash',
      name: 'settings-taxes-trash-list',
      component: () => import('@/views/settings/general/taxes/list/TaxesTrashList.vue'),
      layout: 'Taxes Trash',
      meta: GENERAL_META,
    },
  ],
}
