import { CATALOG_MAIN_META } from '@/router/meta/main/catalog'

export default {
  path: 'transfer',
  name: 'home-orders-transfer',
  layout: 'Warehouses',
  component: () => import('@/views/main/orders/view/transfer/Transfer.vue'),
  redirect: { name: 'home-orders-transfer-list' },
  children: [
    {
      path: '',
      name: 'home-orders-transfer-list',
      component: () => import('@/views/main/orders/view/transfer/list/List.vue'),
      layout: 'Home Orders View',
      meta: CATALOG_MAIN_META,
    },
  ],
}
