<template>
  <b-form-group
    :label-class="checkRequired(field) ? 'inputLabel' : 'inputLabelNormal'"
    :label="$t(field.label)"
    :label-for="name"
  >
    <validation-provider
      #default="{ errors }"
      :name="$t(field.label || field.placeholder)"
      :rules="field.rules"
      :vid="name"
      class="h-100"
    >
      <b-form-textarea
        :id="name"
        v-bind="getProps(field)"
        :placeholder="getPlaceholder(field)"
        :value="value"
        :disabled="isEditable"
        @input="$emit('input', $event)"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormTextarea, BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { inputFieldsVisibility } from '@/views/components/DynamicForm/mixins'

export default {
  name: 'LTextarea',
  components: {
    BFormTextarea,
    BFormGroup,
    ValidationProvider,
  },
  mixins: [inputFieldsVisibility],
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped></style>
