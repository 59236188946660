import { CUSTOMER_MAIN_META } from '../../meta/main/customers'

export default {
  path: 'financial-info',
  name: 'home-customers-financial-info',
  component: () => import('@/views/main/customers/view/financial-info/FinancialInfo.vue'),
  layout: 'Financial Info',
  redirect: { name: 'home-customers-financial-info-list' },
  children: [
    {
      path: '',
      name: 'home-customers-financial-info-list',
      component: () => import('@/views/main/customers/view/financial-info/list/List.vue'),
      layout: 'Financial Info List',
      meta: CUSTOMER_MAIN_META,
    },
    {
      path: 'trash',
      name: 'home-customers-financial-info-trash-list',
      component: () => import('@/views/main/customers/view/financial-info/list/TrashList.vue'),
      layout: 'Financial Info Trash List',
      meta: CUSTOMER_MAIN_META,
    },
    {
      path: 'create',
      name: 'home-customers-financial-info-create',
      component: () => import('@/views/main/customers/view/financial-info/create/Create.vue'),
      layout: 'Financial Info Create',
      meta: CUSTOMER_MAIN_META,
    },
    {
      path: ':finInfoId/update',
      name: 'home-customers-financial-info-update',
      component: () => import('@/views/main/customers/view/financial-info/update/Update.vue'),
      layout: 'Financial Info Update',
      meta: CUSTOMER_MAIN_META,
    },
  ],
}
