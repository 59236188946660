import { CATALOG_MAIN_META } from '../../meta/settings/catalog'

export default {
  path: 'pricing',
  name: 'settings-catalog-pricing',
  component: () => import('@/views/settings/catalog/pricing/Pricing.vue'),
  layout: 'Pricing Tiers',
  redirect: { name: 'settings-catalog-price-list' },
  children: [
    {
      path: '',
      name: 'settings-catalog-price-list',
      component: () => import('@/views/settings/catalog/pricing/list/PricingList.vue'),
      layout: 'Pricing List',
      meta: CATALOG_MAIN_META,
    },
    {
      path: 'rules-trash-list',
      name: 'settings-catalog-price-rules-trash-list',
      component: () => import('@/views/settings/catalog/pricing/list/PricingRulesListTrash.vue'),
      layout: 'Pricing List',
      meta: CATALOG_MAIN_META,
    },
    {
      path: 'tiers-trash-list',
      name: 'settings-catalog-price-tiers-trash-list',
      component: () => import('@/views/settings/catalog/pricing/list/PricingTiersListTrash.vue'),
      layout: 'Pricing List',
      meta: CATALOG_MAIN_META,
    },
    {
      path: 'discounts-trash-list',
      name: 'settings-catalog-discounts-trash-list',
      component: () => import('@/views/settings/catalog/pricing/list/DiscountsListTrash.vue'),
      layout: 'Pricing List',
      meta: CATALOG_MAIN_META,
    },
    {
      path: 'create',
      name: 'settings-catalog-price-tiers-create',
      component: () => import('@/views/settings/catalog/pricing/create/PricingTiersCreate.vue'),
      layout: 'Pricing Tiers Create',
      meta: CATALOG_MAIN_META,
    },
    {
      path: 'create-price',
      name: 'settings-catalog-price-create',
      component: () => import('@/views/settings/catalog/pricing/create/PricingCreate.vue'),
      layout: 'Pricing Create',
      meta: CATALOG_MAIN_META,
    },
    {
      path: 'create-discount',
      name: 'settings-catalog-discount-create',
      component: () => import('@/views/settings/catalog/pricing/create/DiscountCreate.vue'),
      layout: 'Discount Create',
      meta: CATALOG_MAIN_META,
    },
    {
      path: ':id/update',
      name: 'settings-catalog-price-tiers-update',
      component: () => import('@/views/settings/catalog/pricing/update/PricingTiersUpdate.vue'),
      layout: 'Pricing Tiers Update',
      meta: CATALOG_MAIN_META,
    },
    {
      path: ':id/update-pricing-rule',
      name: 'settings-catalog-pricing-rules-update',
      component: () => import('@/views/settings/catalog/pricing/update/PricingRulesUpdate.vue'),
      layout: 'Pricing Rules Update',
      meta: CATALOG_MAIN_META,
    },
    {
      path: ':id/update-discount',
      name: 'settings-catalog-discounts-update',
      component: () => import('@/views/settings/catalog/pricing/update/DiscountsUpdate.vue'),
      layout: 'Discounts Update',
      meta: CATALOG_MAIN_META,
    },
  ],
}
