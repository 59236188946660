import { CATALOG_MAIN_META } from '@/router/meta/main/catalog'

export default {
  path: 'purchase-cr',
  name: 'home-orders-purchase-cr',
  layout: 'Warehouses',
  component: () => import('@/views/main/orders/view/purchase/Purchase.vue'),
  redirect: { name: 'home-orders-purchase-cr-list' },
  children: [
    {
      path: '',
      name: 'home-orders-purchase-cr-list',
      component: () => import('@/views/main/orders/view/purchase/list/List.vue'),
      layout: 'Home Orders View',
      meta: CATALOG_MAIN_META,
    },
    {
      path: '',
      name: 'home-orders-purchase-cr-create-view',
      component: () => import('@/views/main/orders/view/purchase/create/Create.vue'),
      layout: 'Purchase Orders Create',
      children: [
        {
          path: 'create',
          name: 'home-orders-purchase-cr-create',
          component: () => import('@/views/main/orders/view/purchase/create/DirectRequestForQuote.vue'),
          layout: 'Purchase Orders Create',
          meta: CATALOG_MAIN_META,
        },
        {
          path: 'update',
          name: 'home-orders-purchase-cr-update',
          component: () => import('@/views/main/orders/view/purchase/update/Update.vue'),
          layout: 'Purchase Orders Update',
          meta: CATALOG_MAIN_META,
        },
        {
          path: 'direct-request-for-quote/:id',
          name: 'direct-request-for-quote',
          component: () => import('@/views/main/orders/view/purchase/update/DirectRequestForQuote.vue'),
          layout: 'Purchase Orders Create',
          meta: CATALOG_MAIN_META,
        },
        {
          path: 'rfq-for-supplier/:id',
          name: 'home-orders-purchase-rfq-for-supplier',
          component: () => import('@/views/main/orders/view/purchase/create/RFQForSupplier.vue'),
          layout: 'Purchase Orders Create',
          meta: CATALOG_MAIN_META,
        },
        {
          path: 'received-quote-from-supplier/:id',
          name: 'home-orders-purchase-register-received-quote-from-supplier',
          component: () => import('@/views/main/orders/view/purchase/create/ReceivedQuoteFromSupplier.vue'),
          layout: 'Purchase Orders Create',
          meta: CATALOG_MAIN_META,
        },
        {
          path: 'purchase-order-for-supplier/:id',
          name: 'purchase-order-for-supplier',
          component: () => import('@/views/main/orders/view/purchase/create/PurchaseOrderForSupplier.vue'),
          layout: 'Purchase Orders Create',
          meta: CATALOG_MAIN_META,
        },
      ],
    },
  ],
}
