import { GENERAL_META } from '../../meta/settings/general'

const getFullRouteName = routeName => `settings-general-integrations-${routeName}`
export default {
  path: 'integrations',
  name: 'settings-general-integrations',
  component: () => import('@/views/settings/general/integrations/Integrations.vue'),
  layout: 'Integrations',
  redirect: { name: getFullRouteName('list') },
  children: [
    {
      path: '',
      name: getFullRouteName('list'),
      component: () => import('@/views/settings/general/integrations/list/List.vue'),
      layout: 'Integration List',
      meta: GENERAL_META,
    },
    {
      path: 'smtp',
      name: getFullRouteName('smtp'),
      component: () => import('@/views/settings/general/integrations/view/smtp/SMTP.vue'),
      layout: 'Integration SMTP',
      meta: GENERAL_META,
    },
    {
      path: 'imap',
      name: getFullRouteName('imap'),
      component: () => import('@/views/settings/general/integrations/view/imap/IMAP.vue'),
      layout: 'Integration IMAP',
      meta: GENERAL_META,
    },
    {
      path: 'cardknox',
      name: getFullRouteName('cardknox'),
      component: () => import('@/views/settings/general/integrations/view/cardknox/Cardknox.vue'),
      layout: 'Integrations Cardknox',
      children: [
        {
          path: ':id',
          name: 'settings-general-integrations-cardknox-view',
          component: () => import('@/views/settings/general/integrations/view/cardknox/Cardknox.vue'),
          layout: 'Integration Cardknox',
          meta: GENERAL_META,
        },
      ],
    },
    {
      path: 'slack',
      name: getFullRouteName('slack'),
      component: () => import('@/views/settings/general/integrations/view/slack/Slack.vue'),
      layout: 'Integration Slack',
      meta: GENERAL_META,
    },
    {
      path: 'quickbooks',
      name: getFullRouteName('quickbooks'),
      component: () => import('@/views/settings/general/integrations/view/quickbooks/Quickbooks.vue'),
      layout: 'Integration Quickbooks',
      meta: GENERAL_META,
    },
    {
      path: 'idscan',
      name: getFullRouteName('idscan'),
      component: () => import('@/views/settings/general/integrations/view/idscan/IDScan.vue'),
      layout: 'Integration IDScan',
      meta: GENERAL_META,
    },
    {
      path: 'telesign',
      name: getFullRouteName('telesign'),
      component: () => import('@/views/settings/general/integrations/view/telesign/Telesign.vue'),
      layout: 'Integration Telesign',
      meta: GENERAL_META,
    },
    // {
    //   path: 'tloxp',
    //   name: getFullRouteName('tloxp'),
    //   component: () => import('@/views/settings/general/integrations/view/tloxp/TLOxp.vue'),
    //   layout: 'Integration TLOxp',
    //   meta: GENERAL_META,
    // },
    {
      path: 'usps',
      name: getFullRouteName('usps'),
      component: () => import('@/views/settings/general/integrations/view/usps/USPS.vue'),
      layout: 'Integration USPS',
      meta: GENERAL_META,
    },
    {
      path: 'cs-cart',
      name: getFullRouteName('cs-cart'),
      component: () => import('@/views/settings/general/integrations/view/cs-cart/CSCart.vue'),
      layout: 'Integration CS-Cart',
      meta: GENERAL_META,
    },
    {
      path: 'emailage',
      name: getFullRouteName('emailage'),
      component: () => import('@/views/settings/general/integrations/view/emailage/Emailage.vue'),
      layout: 'Integration Emailage',
      meta: GENERAL_META,
    },
    {
      path: 'keycloak',
      name: getFullRouteName('keycloak'),
      component: () => import('@/views/settings/general/integrations/view/keycloak/KeyCloak.vue'),
      layout: 'Integration KeyCloak',
      meta: GENERAL_META,
    },
  ],
}
