<template>
  <div>
    <div class="d-flex justify-content-center mt-1">
      <b-skeleton
        height="36px"
        width="40%"
      />
    </div>
    <b-row>
      <b-col md="2">
        <b-skeleton
          v-for="count in 12"
          :key="count"
          height="46px"
          style="margin-bottom: 2px"
        />
      </b-col>
      <b-col
        class="d-flex"
        md="10"
        style="padding: 20px 20px 20px 2px"
      >
        <b-col
          md="6"
          style="padding-left: 2px"
        >
          <div
            v-for="count in 8"
            :key="count"
          >
            <b-skeleton
              width="30%"
              height="20px"
            />
            <b-skeleton
              height="35px"
              class="mb-1"
            />
          </div>
        </b-col>
        <b-col
          md="6"
          style="padding-left: 2px"
        >
          <div
            v-for="count in 8"
            :key="count"
          >
            <b-skeleton
              width="30%"
              height="20px"
            />
            <b-skeleton
              height="35px"
              class="mb-1"
            />
          </div>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BSkeleton, BCol,
} from 'bootstrap-vue'

export default {
  name: 'BCoreSkeleton',
  components: {
    BCol,
    BRow,
    BSkeleton,
  },
}
</script>
