<template>
  <!--  :label-class="isRequiredField || checkRequired(field) ? checkRequired(field) ? 'inputLabel': 'inputLabelNormal': 'inputLabelNormal'"-->
  <b-form-group
    :label-class="checkRequired(rules) ? 'inputLabel': 'inputLabelNormal'"
    :label="$t(field.label)"
    :label-for="name"
  >
    <validation-provider
      #default="{ errors }"
      :name="$t(field.label || field.placeholder)"
      :rules="rules.rules"
      :vid="name"
    >
      <div class="d-flex align-items-center">
        <b-form-input
          id="customDelimiter"
          ref="inputRef"
          :value="+value"
          class="form-control"
          :disabled="isEditable"
          v-bind="getProps(field)"
          :placeholder="getPlaceholder(field)"
          @click.stop="$emit('click', $event)"
          @focus.stop="$emit('focus', $event)"
        />
        <slot name="input-extra-el" />
      </div>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>

import { ValidationProvider } from 'vee-validate'
import { inputFieldsVisibility } from '@/views/components/DynamicForm/mixins'
import { BFormGroup, BFormInput } from 'bootstrap-vue'
import { useCurrencyInput } from 'vue-currency-input'

export default {
  name: 'LCurrencyMaskInput',
  components: {
    ValidationProvider,
    BFormGroup,
    BFormInput,
  },
  mixins: [inputFieldsVisibility],
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number],
      default: () => null,
    },
    name: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isRequiredField: {
      type: Boolean,
      default: () => false,
    },
    formatter: {
      type: [Function, undefined],
      default: undefined,
    },
  },
  computed: {
    rules() {
      let rules = ''
      if (this.isRequiredField && (!this.field.rules || !this.field.rules.includes('required'))) {
        rules += 'required|'
      }
      if (this.field.rules && typeof this.field.rules === 'string') {
        rules += this.field.rules
      }
      if (rules.charAt(rules.length - 1) === '|') {
        rules = rules.slice(0, -1)
      }
      return { rules }
    },
  },

  setup(props) {
  //= == === === === === === === === === === === === === === === === === === === === === === === === === === === === === === =>
  // HI GUYS !!!
  // IF YOU NEED TO SET A NEW OPTIONS FOR CURRENCY USE THE DOC: ==> https://dm4t2.github.io/vue-currency-input/playground.html
  // YOU CAN SEE HERE ONLY DEFAULT OPTIONS
  //= == === === === === === === === === === === === === === === === === === === === === === === === === === === === === === =>
    const propsCurrencyOption = props.field.currencyOptions
    const options = {
      currency: 'USD',
      currencyDisplay: 'symbol',
      precision: 2,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      useGrouping: true,
      accountingSign: false,
    }

    const { inputRef } = useCurrencyInput({ ...options, ...propsCurrencyOption })

    return { inputRef }
  },
}
</script>
