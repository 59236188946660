export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}
export const validatorRequired = value => {
  if (!value) return false
  return true
}

export const validatorFileSize = (value, { size }) => {
  if (!value) {
    return true
  }
  return (value.size < size * 1024 * 1024)
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*[a-z])(?=.*[A-Z])/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorPhoneNumber = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /^[\+]?[0-9]{3}?[(]?[0-9]{2}[)]?[0-9]{3}?[0-9]{2}?[0-9]{2}$/im
  /* eslint-enable no-useless-escape */
  const validPhoneNumber = regExp.test(password)
  return validPhoneNumber
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/)(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}
