<template>
  <b-form-group
    :label-class="checkRequired(field) ? 'inputLabel': 'inputLabelNormal'"
    :label="$t(field.label)"
    :label-for="name"
  >
    <validation-provider
      #default="{ errors }"
      :name="$t(field.label || field.placeholder)"
      :rules="field.rules"
      :vid="name"
    >
      <div :class="`d-flex align-items-center ${isEditable ? 'wysiwyg-editor--disabled' : ''}`">
        <editor
          :id="name"
          class="wysiwyg-editor"
          :api-key="API_KEY"
          v-bind="getProps(field)"
          :value="value"
          :disabled="isEditable"
          @input="$emit('input', $event)"
        />
        <slot name="input-extra-el" />
      </div>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { inputFieldsVisibility } from '@/views/components/DynamicForm/mixins'

export default {
  name: 'WysiwygEditor',
  components: {
    Editor,
    BFormGroup,
    ValidationProvider,
  },
  mixins: [inputFieldsVisibility],
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const API_KEY = process.env.VUE_APP_TINY_EDITOR_API_KEY

    return {
      API_KEY,
    }
  },

  //= == === === === === === === === === === === === === === === === === === === === === === === === === === === === === === =>
  // HI GUYS !!!
  // IF YOU NEED TO SET A NEW OPTIONS FOR WysiwygEditor USE THE DOC: ==> https://www.tiny.cloud/docs/integrations/vue/#tinymcevuejsintegrationquickstartguide
  // YOU CAN SEE HERE ONLY DEFAULT OPTIONS
  //= == === === === === === === === === === === === === === === === === === === === === === === === === === === === === === =>
}
</script>
<style lang="scss">
.tox {
  width: 100% !important;
}

.wysiwyg-editor--disabled #uuid_ifr {
  background: #efefef !important;
  pointer-events: none !important;

  & > button {
    pointer-events: none !important;

    &:focus {
      background-color: transparent !important;
    }
  }

}
  .wysiwyg-editor--disabled  .tox .tox-tbtn:focus {
    background-color: transparent !important;
  }
</style>
