import Vue from 'vue'
import VueRouter from 'vue-router'
import { stringify, parse } from 'qs'
// Routes
// eslint-disable-next-line import/no-cycle
import { getHomeRouteForLoggedInUser, getUserData, isUserLoggedIn } from '@/auth/utils'
import settings from '@/router/settings'
import mainPage from '@/router/main'
// eslint-disable-next-line import/no-cycle
import { canNavigate } from '@/libs/acl/routeProtection'
import { CATALOG_MAIN_META } from '@/router/meta/main/catalog'
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...pages,
    ...settings,
    ...mainPage,
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/icons',
      name: 'icon-list',
      component: () => import('@/views/pages/IconList.vue'),
      meta: CATALOG_MAIN_META,
    },
  ],
  parseQuery(query) {
    return parse(query)
  },
  stringifyQuery(query) {
    const result = stringify(query)

    return result ? (`?${result}`) : ''
  },

})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to) && to.name !== 'auth-logout') {
  // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' })
    return next({ name: 'home' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
