// eslint-disable-next-line import/named
import { GENERAL_FULFILLMENT_META } from '../../meta/settings/fulfillment'

export default {
  path: 'warehouses',
  name: 'settings-fulfillment-warehouses',
  component: () => import('@/views/settings/fulfillment/warehouses/Warehouses.vue'),
  layout: 'Warehouses',
  redirect: { name: 'settings-fulfillment-warehouses-list' },
  children: [
    {
      path: '',
      name: 'settings-fulfillment-warehouses-list',
      component: () => import('@/views/settings/fulfillment/warehouses/list/WarehouseList.vue'),
      layout: 'Warehouse List',
      meta: GENERAL_FULFILLMENT_META,
    },
    {
      path: 'trash',
      name: 'settings-fulfillment-warehouses-trash-list',
      component: () => import('@/views/settings/fulfillment/warehouses/list/WarehouseListTrash.vue'),
      layout: 'Warehouse List',
      meta: GENERAL_FULFILLMENT_META,
    },
    {
      path: 'create',
      name: 'settings-fulfillment-warehouses-create',
      component: () => import('@/views/settings/fulfillment/warehouses/create/WarehouseCreate.vue'),
      layout: 'Warehouse Create',
      meta: GENERAL_FULFILLMENT_META,
    },
    {
      path: ':id/update',
      name: 'settings-fulfillment-warehouses-update',
      component: () => import('@/views/settings/fulfillment/warehouses/update/WarehouseUpdate.vue'),
      layout: 'Warehouse Update',
      meta: GENERAL_FULFILLMENT_META,
    },
  ],
}
