import axios from '@axios'
import { stringify } from 'qs'

const getEndpoint = endpoint => {
  if (typeof endpoint === 'function') {
    return endpoint()
  }
  return endpoint
}
export default function crudActions(endpoint, mapperFunc = (obj = {}) => obj) {
  const list = ({ commit }, queryParams) => new Promise((resolve, reject) => {
    axios.get(`/${getEndpoint(endpoint)}`, {
      params: mapperFunc(queryParams),
      paramsSerializer: params => stringify(params),
    })
      .then(response => {
        const resData = response.data.data.data
        commit('LIST', resData)
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
    //
  })

  const trashList = ({ commit }, queryParams) => new Promise((resolve, reject) => {
    axios.get(`/${getEndpoint(endpoint)}/trashed-list`, {
      params: mapperFunc(queryParams),
      paramsSerializer: params => stringify(params),
    })
      .then(response => {
        commit('LIST', response.data.data.data)
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })

  const get = ({ commit }, id) => new Promise((resolve, reject) => {
    axios.get(`/${getEndpoint(endpoint)}/${id}`)
      .then(response => {
        commit('GET', response.data.data)
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })

  const getDataWithMeta = ({ commit }, id) => new Promise((resolve, reject) => {
    axios.get(`/${getEndpoint(endpoint)}/${id}`)
      .then(response => {
        commit('GET', {
          data: response.data.data,
          meta: response.data.meta,
        })
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })

  const create = (ctx, data) => axios.post(`/${getEndpoint(endpoint)}`, data)

  const update = (ctx, data) => {
    const id = data instanceof FormData ? data.get('id') : data.id
    return axios.put(`/${getEndpoint(endpoint)}/${id}`, data)
  }

  const patch = (ctx, data) => axios.patch(`/${getEndpoint(endpoint)}/${data.id}`, data.body)

  const del = (ctx, data) => axios.delete(`/${getEndpoint(endpoint)}/${data.id}`)

  const moveToTrash = (ctx, data) => axios.post(`/${getEndpoint(endpoint)}/move-to-trashed`, { ids: data })

  const restoreFromTrashList = (ctx, data) => axios.post(`/${getEndpoint(endpoint)}/restore-from-trashed`, { ids: data })
  const statusChange = (ctx, data) => axios.post(`/${getEndpoint(endpoint)}/change-status`, data)

  const batchDelete = (ctx, data) => axios.delete(`/${getEndpoint(endpoint)}/batch-delete`, {
    data: {
      ids: data,
    },
  })

  return {
    list,
    trashList,
    get,
    create,
    update,
    patch,
    del,
    batchDelete,
    moveToTrash,
    getDataWithMeta,
    restoreFromTrashList,
    statusChange,
  }
}
