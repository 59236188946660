<template>
  <b-form-group
    :label-class="checkRequired(field) ? 'inputLabel': 'inputLabelNormal'"
    :label="$t(field.label)"
    :label-for="name"
  >
    <validation-provider
      #default="{ errors }"
      :ref="`refUniqueValProvider${name}`"
      mode="passive"
      :name="$t(field.label || field.placeholder)"
      :rules="field.rules"
      :vid="name"
    >
      <b-input-group class="input-group-merge">
        <b-form-input
          :id="name"
          v-bind="getProps(field)"
          :placeholder="getPlaceholder(field)"
          :value="value"
          :disabled="isEditable"
          @keyup="setError"
          @blur="onValidation"
          @input="emitData"
        />
        <b-input-group-append
          v-if="isLoading"
          is-text
        >
          <b-spinner class="unique-form__spinner" />
        </b-input-group-append>
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BFormInput, BFormGroup, BSpinner, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import store from '@/store'
import uniqueModule from '@/store/unique'
import { onUnmounted } from '@vue/composition-api'
import { inputFieldsVisibility } from '@/views/components/DynamicForm/mixins'

export default {
  name: 'LTextInput',
  components: {
    BFormInput,
    BFormGroup,
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
  },
  mixins: [inputFieldsVisibility],
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    checkUnique: {
      type: [Function, Boolean],
      required: false,
      default: () => true,
    },

    validationFunc: {
      type: Function,
      required: false,
      default: (searchQuery, ctx) => {
        ctx.$store.dispatch(`${ctx.MODULE_NAME}/${ctx.field.actionName}`, { [ctx.field.paramKey]: searchQuery })
          .then(response => {
            if (response !== null && !ctx.additionalRules(response)) {
              ctx.$refs[`refUniqueValProvider${ctx.name}`].setErrors(['This name is already taken'])
            } else {
              ctx.$refs[`refUniqueValProvider${ctx.name}`].reset()
            }
            ctx.$emit('backResponse', response)
          })
          .catch(error => {
            ctx.$emit('backError', error)
          })
          .finally(() => {
            ctx.isLoading = false
          })
      },
    },
  },

  methods: {
    getRequired(field) {
      console.log(field.required)
    },
    onValidation($event) {
      if ($event.target.value) {
        this.isLoading = true
        this.validationFunc($event.target.value, this)
      }
    },
    onValidationByParams(searchQuery) {
      this.isLoading = true
      this.validationFunc(searchQuery, this)
    },
    setError() {
      this.$refs[`refUniqueValProvider${this.name}`].setErrors([' '])
    },
    emitData(event) {
      this.setError()
      this.$emit('input', event)
    },
    changeToValid() {
      this.$refs[`refUniqueValProvider${this.name}`].reset()
    },
    additionalRules(response) {
      if (typeof this.checkUnique === 'function') {
        return this.checkUnique(response)
      }
      return this.checkUnique
    },
  },
  setup() {
    const MODULE_NAME = 'unique'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, uniqueModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      MODULE_NAME,
    }
  },
}
</script>

<style>
.unique-form__spinner.spinner-border{
  width: 1.7rem;
  height: 1.7rem;
}
</style>
