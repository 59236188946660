import { CATALOG_MAIN_META } from '@/router/meta/main/catalog'

export default {
  path: 'service',
  name: 'home-orders-service',
  layout: 'Warehouses',
  component: () => import('@/views/main/orders/view/service/Service.vue'),
  redirect: { name: 'home-orders-service-list' },
  children: [
    {
      path: '',
      name: 'home-orders-service-list',
      component: () => import('@/views/main/orders/view/service/list/List.vue'),
      layout: 'Home Orders View',
      meta: CATALOG_MAIN_META,
    },
    {
      path: '',
      name: 'home-orders-request-service-create-view',
      component: () => import('@/views/main/orders/view/service/create/Create.vue'),
      layout: 'Home Orders View',
      children: [
        {
          path: 'request-service-quote/:id',
          name: 'home-orders-request-service-quote',
          component: () => import('@/views/main/orders/view/service/create/RequestServiceQuote.vue'),
          layout: 'Home Orders View',
          meta: CATALOG_MAIN_META,
        },
        {
          path: 'received-quote-in-house/:id',
          name: 'received-quote-in-house',
          component: () => import('@/views/main/orders/view/service/create/ReceivedQuoteInHouse.vue'),
          layout: 'Purchase Orders Create',
          meta: CATALOG_MAIN_META,
        },
        {
          path: 'request-service-quote-view/:id',
          name: 'request-service-quote-view',
          component: () => import('@/views/main/orders/view/service/create/RequestServiceQuoteView.vue'),
          layout: 'Purchase Orders Create',
          meta: CATALOG_MAIN_META,
        },
        {
          path: 'request-service-quote-in-house-view/:id',
          name: 'request-service-quote-in-house-view',
          component: () => import('@/views/main/orders/view/service/create/RequestServiceQuoteInHouseView.vue'),
          layout: 'Purchase Orders Create',
          meta: CATALOG_MAIN_META,
        },
        {
          path: 'received-quote/:id',
          name: 'received-quote',
          component: () => import('@/views/main/orders/view/service/create/ReceivedQuote.vue'),
          layout: 'Purchase Orders Create',
          meta: CATALOG_MAIN_META,
        },
        {
          path: 'invoice/:id',
          name: 'invoice',
          component: () => import('@/views/main/orders/view/service/create/Invoice.vue'),
          layout: 'Purchase Orders Create',
          meta: CATALOG_MAIN_META,
        },
      ],
    },
  ],
}
