import { Ability } from '@casl/ability'
// eslint-disable-next-line import/no-cycle
import { isUserLoggedIn } from '@/auth/utils'
import jwtDefaultConfig from '@/auth/jwt/jwtDefaultConfig'
import { initialAbility, manageAll } from './config'
//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

const stringifiedUserPermissionKeyName = localStorage.getItem(jwtDefaultConfig.storageUserPermissionKeyName)
const existingAbility = stringifiedUserPermissionKeyName ? JSON.parse(stringifiedUserPermissionKeyName) : manageAll

export default new Ability((isUserLoggedIn() ? [...existingAbility] : initialAbility))
// export default new Ability(isUserLoggedIn() ? manageAll : initialAbility)
