<template>
  <div
    class="d-flex flex-column align-items-center justify-content-center"
    style="height: 80vh; background-color: #dfe0e3; border-radius: 5px"
  >
    <h1
      class="title text-uppercase lightIcon"
    >
      {{ errorStatus }}
    </h1>
    <b-button
      variant="success"
      class="saveBtn font-medium-3 font-weight-bolder"
      style="height: 45px"
      @click="redirectPage"
    >{{ title }}</b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'ComponentNotFound',
  components: {
    BButton,
  },
  props: {
    errorStatus: {
      type: [String, Number],
      required: false,
      default: () => 'Undefined Status',
    },
    buttonLink: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    redirectPage() {
      this.$router.push(this.$props.buttonLink)
    },
  },
}
</script>
<style scoped>
.title {
  font-size: 160px;
  text-align: center;
}
</style>
