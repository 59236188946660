import { GENERAL_META } from '../../meta/settings/general'

export default {
  path: 'payment-methods',
  name: 'settings-payment-methods',
  component: () => import('@/views/settings/general/payment-methods/PaymentMethods.vue'),
  layout: 'Payment Methods',
  redirect: { name: 'settings-payment-methods-list' },
  children: [
    {
      path: '',
      name: 'settings-payment-methods-list',
      component: () => import('@/views/settings/general/payment-methods/list/List.vue'),
      layout: 'Payment Methods List',
      meta: GENERAL_META,
    },
    {
      path: 'trash-list',
      name: 'settings-payment-methods-trash-list',
      component: () => import('@/views/settings/general/payment-methods/list/TrashList.vue'),
      layout: 'Payment Methods Trash List',
      meta: GENERAL_META,
    },
    {
      path: 'create',
      name: 'settings-payment-methods-create',
      component: () => import('@/views/settings/general/payment-methods/create/Create.vue'),
      layout: 'Payment Methods Create',
      meta: GENERAL_META,
    },
    {
      path: ':id/update',
      name: 'settings-payment-methods-update',
      component: () => import('@/views/settings/general/payment-methods/update/Update.vue'),
      layout: 'Payment Methods Update',
      meta: GENERAL_META,
    },
  ],
}
