<template>
  <b-form-group
    :label-class="checkRequired(field) ? 'inputLabel' : 'inputLabelNormal'"
    :label="$t(field.label)"
    :label-for="name"
  >
    <validation-provider
      #default="{ errors }"
      :name="$t(field.label || field.placeholder)"
      :rules="field.rules"
      :vid="name"
    >
      <b-input-group class="d-flex justify-content-between">
          <div class="input-group-merge d-flex flex-grow-1">
            <b-form-input
                :id="name"
                v-bind="getProps(field)"
                :placeholder="getPlaceholder(field)"
                :value="value"
                :type="passwordFieldType"
                @input="$emit('input', $event)"
                :disabled="isEditable"
            />
            <b-input-group-append is-text class="ml-n1">
              <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </div>
        <div v-if="passwordGenerate">
          <b-button
              variant="success"
              class="d-flex align-items-center"
              style="height: 36px; width: 36px; padding: 8px; margin-left: 10px;"
              @click="$emit('suggestion', $event)"
              :disabled="isEditable"
          >
            <feather-icon
                icon="LMagicIcon"
                size="30"
            />
          </b-button>
        </div>
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BInputGroupAppend,
  BInputGroup, BButton,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { inputFieldsVisibility } from '@/views/components/DynamicForm/mixins'
import vSelect from "vue-select";

export default {
  name: 'TextInputComponent',
  components: {
    BButton,
    vSelect,
    BFormInput,
    BFormGroup,
    BInputGroupAppend,
    BInputGroup,
    ValidationProvider,
  },
  mixins: [togglePasswordVisibility, inputFieldsVisibility],
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    passwordGenerate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
}
</script>

<style scoped>
.input-group-append > div{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
