import { CUSTOMER_MAIN_META } from '../../meta/settings/customers'

export default [
  {
    path: 'customer',
    name: 'settings-customer',
    component: () => import('@/views/settings/customer/SettingsCustomer.vue'),
    redirect: { name: 'settings-customer-defaults' },
    children: [
      {
        path: 'defaults',
        name: 'settings-customer-defaults',
        component: () => import('@/views/settings/customer/defaults/Defaults.vue'),
        layout: 'Customer',
        meta: CUSTOMER_MAIN_META,
      },
      {
        path: 'templates',
        name: 'settings-customer-templates',
        component: () => import('@/views/settings/customer/templates/Templates.vue'),
        layout: 'Customer Templates',
        meta: CUSTOMER_MAIN_META,
      }
    ],
  },
]
