<template>
  <b-form-group
    :label-class="checkRequired(field) ? 'inputLabel' : 'inputLabelNormal'"
    :label="$t(field.label)"
    :label-for="name"
  >
    <validation-provider
      #default="{ errors }"
      :name="$t(field.label || field.placeholder)"
      :rules="field.rules"
      :vid="name"
    >
      <b-form-datepicker
        :id="name"
        v-bind="getProps(field)"
        :placeholder="field.label"
        :value="value"
        today-button
        reset-button
        close-button
        @input="$emit('input', $event)"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormDatepicker, BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { inputFieldsVisibility } from '@/views/components/DynamicForm/mixins'

export default {
  name: 'LDataPicker',
  components: {
    BFormDatepicker,
    BFormGroup,
    ValidationProvider,
  },
  mixins: [inputFieldsVisibility],
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
  },
}
</script>

<style>
.label {
  padding-left: 10px;
  font-weight: bold;
}
</style>
