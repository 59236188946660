<template>
  <b-form-group>
    <validation-provider
      #default="{ errors }"
      :name="$t(field.label || field.placeholder)"
      :rules="field.rules"
      :vid="name"
    >
      <div :class="`${!field.isFlexible && 'd-flex'} input-number-wrapper`">
        <label
          :class="checkRequired(field) ? 'inputLabel': 'inputLabelNormal'"
          :for="name"
        >{{ $t(field.label) }}</label>
        <b-form-input
          :id="name"
          type="number"
          v-bind="getProps(field)"
          :placeholder="getPlaceholder(field)"
          :value="value"
          :disabled="isEditable"
          @input="$emit('input', $event)"
        />
        <span class="text-capitalize">{{ unit }}</span>
      </div>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormInput, BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { inputFieldsVisibility } from '@/views/components/DynamicForm/mixins'

export default {
  name: 'LNumericInput',
  components: {
    BFormInput,
    BFormGroup,
    ValidationProvider,
  },
  mixins: [inputFieldsVisibility],
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    unit() {
      if (this.$store.state['phy-properties']?.phyProperties) {
        return this.$store.state['phy-properties']?.phyPropertiesForm?.measurement_unit_id?.[this.field.unitName]
      }
      return ''
    },
    factorPhy() {
      if (this.$store.state['phy-properties'].phyProperties) {
        return this.$store.state['phy-properties'].phyPropertiesForm.measurement_unit_id?.[this.field.factorPhy]
      }
      return ''
    },
  },
}
</script>

<style>
.input-number-wrapper {
  gap: 12px !important;
  align-items: center;
  font-size: 16px;
}
</style>
