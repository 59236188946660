import paymentTerms from '@/router/settings/general/payment-terms'
import paymentMethods from '@/router/settings/general/payment-methods'
import integrations from '@/router/settings/general/integrations'
import taxes from '@/router/settings/general/taxes'
// eslint-disable-next-line import/named
import { GENERAL_META } from '../../meta/settings/general'

export default [
  {
    path: 'general',
    name: 'settings-general',
    component: () => import('@/views/settings/general/General.vue'),
    redirect: { name: 'settings-general-organization' },
    children: [
      {
        path: 'organization',
        name: 'settings-general-organization',
        component: () => import('@/views/settings/general/organization/Organization.vue'),
        redirect: { name: 'settings-organization-update' },
        children: [
          {
            path: '',
            name: 'settings-organization-update',
            component: () => import('@/views/settings/general/organization/organization-update/OrganizationUpdate.vue'),
            layout: 'Organization Update',
            meta: GENERAL_META,
          },
        ],
      },
      taxes,
      integrations,
      {
        path: 'translations',
        name: 'settings-general-translations',
        component: () => import('@/views/settings/general/translations/Translations.vue'),
        layout: 'translations',
        meta: GENERAL_META,
      },
      paymentTerms,
      paymentMethods,
    ],
  },
]
