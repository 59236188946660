import axios from '@axios'

const get = (url, responseKey = null, queryParams = {}, commit) => new Promise((resolve, reject) => {
  axios.get(url, { params: queryParams })
    .then(response => {
      const { data } = response
      const resolvedData = responseKey ? data[responseKey] : data
      resolve(resolvedData)
    })
    .catch(error => {
      reject(error)
    })
})
export default {
  getCustomerByName({ commit }, queryParams) {
    return get('customers/get-by-name', 'customer', queryParams, commit)
  },
}
