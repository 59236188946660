<template>
  <div class="render-list">
    <div
      v-for="row in rows"
      :key="row"
      class="render-list-item"
    >
      <BRow class="align-items-center">
        <BCol cols="6">
          <slot :name="`row-${row}-left`" />
        </BCol>
        <BCol cols="6">
          <slot :name="`row-${row}-right`" />
        </BCol>
      </BRow>
      <slot :name="`row-${row}-whole`" />
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'RenderList',
  components: {
    BRow,
    BCol,
  },
  props: {
    rows: {
      type: [String, Number],
      required: true,
    },
  },
}
</script>
<style lang="scss">
.render-list {
  &-item {
    padding: 7px 12px;
    &:not(:last-child) {
      border-bottom: 1px solid #DDE3E7;
    }

    &:nth-child(odd) {
      background-color: #FFFFFF;
    }
  }
}
</style>
