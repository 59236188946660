<template>
  <b-modal
    id="bs-warning-modal"
    ref="bs-warning-modal"
    centered
    hide-header
    body-class="bs-warning-modal__body px-2 py-2"
    footer-class="bs-warning-modal__footer justify-content-between"
    ok-variant="success"
    ok-title="Discard"
    :cancel-title="$t('cancel')"
    modal-class="bs-warning-modal"
  >
    <div class="text-center py-2">
      <feather-icon
        icon="LWarningIcon"
        size="44"
        class="mb-2"
      />
      <h4 class="font-weight-bolder">
        {{ $t('Are you sure you want to discard changes?') }}
      </h4>
    </div>
  </b-modal>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'BSWarningModal',
  methods: {
    showModal() {
      this.$bvModal.show('bs-warning-modal')
    },
    confirmWarning(next, isChanges, onSubmit = false) {
      if (onSubmit) {
        next()
        this.$emit('onNextTick')
        return
      }
      if (isChanges) {
        this.showModal()
        this.$nextTick(() => {
          this.$refs['bs-warning-modal'].$once('hide', bvEvt => {
            if (bvEvt.trigger === 'ok') {
              this.$refs['bs-warning-modal'].$once('hidden', () => { next() })
              // start -> This line below is used for restarting the modal of warehouse switch
              useJwt.setFormChangesOnForm(false)
              // end
              setTimeout(() => {
                this.$emit('onNextTick')
              }, 1000)
            } else {
              next(false)
            }
          })
        })
      } else {
        next()
      }
    },
  },
}
</script>
<style scoped lang="scss">
#bs-warning-modal {
  .modal-dialog {
    max-width: 500px !important;
  }
}
</style>
