import systemUsers from './users/system-users'
import general from './general'
import catalog from './catalog'
import customer from './customer'
import inventory from './inventory'
import fulfillment from './fulfillment'
import orders from './orders'
import reports from './reports'

export default [
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/Settings.vue'),
    redirect: { name: 'settings-general-organization' },
    children: [
      ...systemUsers,
      ...general,
      ...catalog,
      ...customer,
      ...inventory,
      ...fulfillment,
      ...orders,
      ...reports,
    ],
  },
]
