// We haven't added icon's computed property because it makes this mixin coupled with UI
export const inputFieldsVisibility = {
  data() {
    return {
      placeholder: null,
      label: null,
      selectOptions: [],
      isSelectLoading: false,
      isSearchable: false,
      isLoading: false,
    }
  },
  methods: {
    getPlaceholder(fields) {
      if (fields.placeholder) {
        return fields.placeholder
      }
      return fields.label
    },
    getProps() {
      return {
        ...{ min: 0 },
        ...this.field.options ?? {},
      }
    },
    checkRequired(field) {
      if (!field.rules) return false
      return field.rules.split('|').includes('required')
    },
  },
}

export const _ = null
