<template>
  <div>
    <div
      v-if="isUpdateForm"
      class="d-flex justify-content-end"
    >
      <div
        class="d-flex"
        style="gap: 12px"
      >
        <button
          v-if="isViewTrashButton"
          class="border-dashed btn d-flex p-0 align-items-center justify-content-center"
          style="width: 36px; height: 36px; color: #646464"
          @click="trashButtonHandler"
        >
          <feather-icon icon="LTrashIcon" />
        </button>
        <button
          v-if="isCalendarButton"
          class="border-dashed btn d-flex p-0 align-items-center justify-content-center"
          style="width: 36px; height: 36px; color: #646464"
        >
          <feather-icon icon="LCalendarsIcon" />
        </button>
        <Can
          v-if="isPenButton"
          :i="canEdit.action"
          :a="canEdit.subject"
        >
          <feather-icon
            :title="$t('Edit')"
            class="cursor-pointer"
            :icon="isEditable ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
            size="36"
            @click="changeEditable"
          />
        </Can>
      </div>
    </div>
    <slot />
  </div>

</template>

<script>
export default {
  name: 'FormFeaturesWrapper',
  props: {
    isPenButton: {
      type: Boolean,
      default: true,
      required: false,
    },
    isCalendarButton: {
      type: Boolean,
      default: true,
      required: false,
    },
    isUpdateForm: {
      type: Boolean,
      require: true,
    },
    canEdit: {
      type: Object,
      required: true,
    },
    isViewTrashButton: {
      type: Boolean,
      require: true,
    },
    isEditable: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      // isEditPenAvailable: this.$store.state.app.organizationStatus,
    }
  },
  methods: {
    changeEditable() {
      this.$emit('changeEditable')
    },
    trashButtonHandler() {
      this.$emit('trashButtonHandler')
    },
  },
}
</script>
