import { GENERAL_META } from '../../meta/settings/general'

export default {
  path: 'payment-terms',
  name: 'settings-payment-terms',
  component: () => import('@/views/settings/general/payment-terms/PaymentTerms.vue'),
  layout: 'Payment Terms',
  redirect: { name: 'settings-payment-terms-list' },
  children: [
    {
      path: '',
      name: 'settings-payment-terms-list',
      component: () => import('@/views/settings/general/payment-terms/list/PaymentTermsList.vue'),
      layout: 'Payment Terms List',
      meta: GENERAL_META,
    },
    {
      path: 'trash',
      name: 'settings-payment-terms-trash-list',
      component: () => import('@/views/settings/general/payment-terms/list/PaymentTermsTrashList.vue'),
      layout: 'Payment Terms Trash List',
      meta: GENERAL_META,
    },
    {
      path: 'create',
      name: 'settings-payment-terms-create',
      component: () => import('@/views/settings/general/payment-terms/create/PaymentTermsCreate.vue'),
      layout: 'Payment Term Create',
      meta: GENERAL_META,
    },
    {
      path: ':id/update',
      name: 'settings-payment-terms-update',
      component: () => import('@/views/settings/general/payment-terms/update/PaymentTermsUpdate.vue'),
      layout: 'Payment Term Update',
      meta: GENERAL_META,
    },
  ],
}
