import { HOME_META } from '../../meta/main/home'

export default [
  {
    path: '',
    name: 'home',
    component: () => import('@/views/main/home/Home.vue'),
    meta: HOME_META,
  },
]
