<template>
  <div style="vertical-align: center">
    <div v-if="onIndexComparison">
      <div
        class="d-flex justify-content-center"
        style="gap: 8px"
      >
        <div>
          <button-dashed
            type="button"
            :class="`button-dashed--disabled d-flex justify-content-center align-items-center m-0 ${quantity === MIN_LIMIT ? ' opacity-50' : ''}`"
            :disabled="quantity === MIN_LIMIT || isDisabledMinus"
            style="height: 26px; width: 32px; padding: 0;"
            @click.stop="counterHandler('minus')"
          >
            <feather-icon
              icon="MinusIcon"
              size="16"
            />
          </button-dashed>
        </div>
        <div>
          <b-form-input
            v-model="quantity"
            :class="`custom-number-input ${quantity > MIN_LIMIT ? 'bg-white': ''}`"
            style="width: 42px; height: 26px"
            disabled
            @input="countInputHandler($event)"
          />
        </div>
        <div>
          <button-dashed
            type="button"
            :class="`button-dashed--disabled d-flex justify-content-center align-items-center m-0 ${(quantity === MAX_LIMIT) || isDisabledPlus ? ' opacity-50' : ''}`"
            :disabled="(quantity === MAX_LIMIT) || isDisabledPlus"
            style="height: 26px; width: 32px; padding: 0;"
            @click.stop="counterHandler"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
          </button-dashed>
        </div>
      </div>
    </div>
    <div
      v-else
      style="height: 26px; padding: 0"
      class="d-flex justify-content-center align-items-center"
    >
      <slot name="switch-off-action" />
    </div>
  </div>
</template>

<script>
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'
import { BFormInput } from 'bootstrap-vue'
import config from './config'

export default {
  name: 'IncrementDecrementInput',
  components: {
    ButtonDashed,
    BFormInput,
  },
  props: {
    onIndexComparison: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    itemKeyNameForAmount: {
      type: String,
      required: true,
    },
    isDisabledPlus: {
      type: Boolean,
      default: false,
    },
    isDisabledMinus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      quantity: this.item[this.itemKeyNameForAmount] ?? this.MIN_LIMIT,
    }
  },
  watch: {
    item: {
      handler() {
        this.quantity = this.item[this.itemKeyNameForAmount] ?? this.MIN_LIMIT
      },
      deep: true,
    },
  },
  methods: {
    setValue(neededValue) {
      this.quantity = neededValue
    },
    counterHandler(countType = 'plus', countBy = 1) {
      // Checking countType
      if (countType === 'minus') {
        this.quantity -= countBy
      } else {
        this.quantity += countBy
      }

      this.item[this.itemKeyNameForAmount] = this.quantity
      this.$emit('onIncrementDecrementAction')
    },
    countInputHandler($event) {
      if (Number($event) >= 10000) {
        $event.preventDefault()
      }
    },
  },
  setup() {
    const { MAX_LIMIT, MIN_LIMIT } = config()

    return {
      MAX_LIMIT,
      MIN_LIMIT,
    }
  },
}
</script>
<style>
  .custom-number-input {
    margin: 0 auto;
    text-align: center;
    width: 50px !important;
    padding: 0 5px 0 5px !important;
  }
</style>
