import address from '@/router/main/customers/address'
import financialInfo from '@/router/main/customers/financial-info'
import { CUSTOMER_CONTACT_META, CUSTOMER_MAIN_META } from '../../meta/main/customers'

export default [
  {
    path: 'customers',
    name: 'home-customers',
    component: () => import('@/views/main/customers/Customers.vue'),
    redirect: { name: 'home-customers-list' },
    children: [
      {
        path: '',
        name: 'home-customers-list',
        component: () => import('@/views/main/customers/list/CustomerList.vue'),
        layout: 'Customer List',
        meta: CUSTOMER_MAIN_META,
      },
      {
        path: 'trash',
        name: 'home-customers-trash-list',
        component: () => import('@/views/main/customers/list/CustomerTrashList.vue'),
        layout: 'Customer Trash List',
        meta: CUSTOMER_MAIN_META,
      },
      {
        path: 'create',
        name: 'home-customers-create',
        component: () => import('@/views/main/customers/create/CustomerCreate.vue'),
        layout: 'Customer Create',
        meta: CUSTOMER_MAIN_META,
      },
      {
        path: ':id',
        name: 'home-customers-view',
        component: () => import('@/views/main/customers/view/CustomerView.vue'),
        layout: 'Customer View',
        redirect: { name: 'home-customers-general-info' },
        children: [
          {
            path: 'general-info',
            name: 'home-customers-general-info',
            component: () => import('@/views/main/customers/view/general-info/General-info.vue'),
            layout: 'GeneralInfo',
            meta: CUSTOMER_MAIN_META,
          },
          address,
          {
            path: 'contacts',
            name: 'home-customers-contacts',
            component: () => import('@/views/main/customers/view/contacts/Contacts.vue'),
            layout: 'Contacts',
            redirect: { name: 'home-customers-contacts-list' },
            children: [
              {
                path: '',
                name: 'home-customers-contacts-list',
                component: () => import('@/views/main/customers/view/contacts/list/ContactsList.vue'),
                layout: 'Customer Contacts List',
                meta: CUSTOMER_CONTACT_META,
              },
              {
                path: 'customers/:id/contacts',
                name: 'home-customers-contacts-list-redirect',
                component: () => import('@/views/main/customers/view/contacts/list/ContactsList.vue'),
                layout: 'Customer Contacts List',
                meta: CUSTOMER_CONTACT_META,
              },
              {
                path: 'trash',
                name: 'home-customers-contacts-trash',
                component: () => import('@/views/main/customers/view/contacts/list/ContactsTrashList.vue'),
                layout: 'Customer Contacts to Trash',
                meta: CUSTOMER_CONTACT_META,
              },
              {
                path: 'create',
                name: 'home-customers-contacts-create',
                component: () => import('@/views/main/customers/view/contacts/create/ContactsCreate.vue'),
                layout: 'Customer Contacts Create',
                meta: CUSTOMER_CONTACT_META,
              },
              {
                path: ':contact_id',
                name: 'home-customers-contacts-update-id',
                component: () => import('@/views/main/customers/view/contacts/contacts-update/ContactsUpdate.vue'),
                layout: 'Customer Contacts Create',
                redirect: { name: 'home-customers-contacts-update' },
                children: [
                  {
                    path: 'update',
                    name: 'home-customers-contacts-update',
                    component: () => import('@/views/main/customers/view/contacts/contacts-update/ContactsUpdate.vue'),
                    layout: 'Customer Contacts Create',
                    meta: CUSTOMER_CONTACT_META,
                  },
                ],
              },
            ],
          },
          {
            path: 'verification',
            name: 'home-customers-verification',
            component: () => import('@/views/main/customers/view/verification/Verification.vue'),
            layout: 'Verification',
            redirect: { name: 'home-customers-verification-view' },
            children: [
              {
                path: '',
                name: 'home-customers-verification-view',
                component: () => import('@/views/main/customers/view/verification/VerificationView.vue'),
                layout: '',
                meta: CUSTOMER_MAIN_META,
              },
            ],
          },
          financialInfo,
          {
            path: 'sls-by-product',
            name: 'home-customers-sls-by-product',
            component: () => import('@/views/main/customers/view/sls-by-product/SalesByProduct.vue'),
            layout: 'SalesByProduct',
            meta: CUSTOMER_MAIN_META,
          },
          {
            path: 'sls-by-order',
            name: 'home-customers-sls-by-order',
            component: () => import('@/views/main/customers/view/sls-by-order/SalesByOrder.vue'),
            layout: 'SalesByOrder',
            meta: CUSTOMER_MAIN_META,
          },
          {
            path: 'attachments',
            name: 'home-customers-attachments',
            component: () => import('@/views/main/customers/view/attachments/Attachments.vue'),
            layout: 'Attachments',
            redirect: { name: 'home-customers-attachment-list' },
            children: [
              {
                path: '',
                name: 'home-customers-attachment-list',
                component: () => import('@/views/main/customers/view/attachments/list/AttachmentList.vue'),
                layout: 'Customer Attachments List',
                meta: CUSTOMER_MAIN_META,
              },
              {
                path: 'trash',
                name: 'home-customers-attachment-trash',
                component: () => import('@/views/main/customers/view/attachments/list/AttachmentTrashCan.vue'),
                layout: 'Customer Attachments Trash',
                meta: CUSTOMER_MAIN_META,
              },
            ],
          },
          {
            path: 'activity-log',
            name: 'home-customers-activity-log',
            component: () => import('@/views/main/customers/view/activity-log/Activity-log.vue'),
            layout: 'ActivityLog',
            meta: CUSTOMER_MAIN_META,
          },
        ],
      },
    ],
  },
]
