import axios from '@axios'

const get = (url, commit, commitKey, responseKey = null, queryParams = {}) => new Promise((resolve, reject) => {
  axios.get(url, { params: queryParams })
    .then(response => {
      const { data } = response
      commit(commitKey, responseKey ? data.data[responseKey] : data.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})
export default {
  brandsList({ commit }, queryParams) {
    return get('/brands', commit, 'SET_BRANDS_LIST', 'data', queryParams)
  },
  categoriesList({ commit }, queryParams) {
    return get('/categories', commit, 'SET_CATEGORIES_LIST', 'data', queryParams)
  },
  roleList({ commit }, queryParams) {
    return get('/roles', commit, 'SET_ROLE_LIST', 'data', queryParams)
  },
  warehouseList({ commit }, queryParams) {
    return get('/settings/warehouses', commit, 'SET_WAREHOUSE_LIST', 'data', queryParams)
  },
  permissionList({ commit }, queryParams) {
    return get('/permissions', commit, 'SET_PERMISSION_LIST', null, queryParams)
  },
  currencyList({ commit }, queryParams) {
    return get('/currencies', commit, 'SET_CURRENCY_LIST', 'data', queryParams)
  },
  paymentTermList({ commit }, queryParams) {
    return get('/payment-terms', commit, 'SET_PAYMENT_TERM_LIST', 'data', queryParams)
  },
  priceTierList({ commit }, queryParams) {
    return get('/price-tiers', commit, 'SET_PRICE_TIER_LIST', 'data', queryParams)
  },
  pricingRulesList({ commit }, queryParams) {
    return get('/pricing/rules', commit, 'SET_PRICING_RULE_LIST', 'data', queryParams)
  },
  taxRuleList({ commit }, queryParams) {
    return get('/tax-rules', commit, 'SET_TAX_RULE_LIST', 'data', queryParams)
  },
  accountManagerList({ commit }, queryParams) {
    return get('/users', commit, 'SET_ACCOUNT_MANAGER_LIST', 'data', queryParams)
  },
  deliveryTypeList({ commit }, queryParams) {
    return get('/delivery-types', commit, 'SET_DELIVERY_TYPE_LIST', 'data', queryParams)
  },
  countryList({ commit }, queryParams) {
    return get('/countries', commit, 'SET_COUNTRY_LIST', null, queryParams)
  },
  stateList({ commit }, queryParams) {
    return get(`/countries/${queryParams.parentValue}/states`, commit, 'SET_STATE_LIST', null, queryParams)
  },
  statusList(ctx, queryParams) {
    return queryParams
  },
  attachmentCategoryList({ commit }, queryParams) {
    return get('/system/attachment-categories', commit, 'SET_ATTACHMENT_CATEGORY_LIST', null, queryParams)
  },
  timezoneList({ commit }) {
    return get('/time-zones', commit, 'SET_TIMEZONE_LIST')
  },
  dateformatList({ commit }, queryParams) {
    return get('/date-formats', commit, 'SET_DATEFORMAT_LIST', 'data', queryParams)
  },
  measurementUnitsList({ commit }, queryParams) {
    return get('/measurement-units', commit, 'SET_MEASUREMENT_UNITS_LIST', 'data', queryParams)
  },
  sendEmailFromList(ctx, queryParams) {
    return queryParams
  },
  exportFileEncodingList(ctx, queryParams) {
    return queryParams
  },
  languageList({ commit }, queryParams) {
    return get('/system/languages', commit, 'SET_LANGUAGES_LIST', 'data', queryParams)
  },
  settingsDateFormat({ commit }, queryParams) {
    return get('/settings/general/organization', commit, 'SET_SETTINGS_DATE_FORMAT', 'date_format', queryParams)
  },
  paymentOptionList({ commit }, queryParams) {
    return get('/payment-methods', commit, 'SET_PAYMENT_OPTIONS', 'data', queryParams)
  },
  customerRulesList({ commit }, queryParams) {
    return get('/customer-rules', commit, 'SET_CUSTOMER_RULES', '', queryParams)
  },
  organizationData({ commit }) {
    return get('/settings/general/organization', commit, 'SET_ORGANIZATION_DATA', null)
  },
}
