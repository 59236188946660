import { GENERAL_INVENTORY_META } from '../../meta/settings/inventory'

export default [
  {
    path: 'inventory',
    name: 'settings-inventory',
    component: () => import('@/views/settings/inventory/Inventory.vue'),
    redirect: { name: 'settings-asset-general' },
    children: [
      {
        path: 'settings-asset-condition',
        name: 'settings-asset-condition',
        component: () => import('@/views/settings/inventory/asset-condition/AssetCondition.vue'),
        redirect: { name: 'inventory-asset-condition-list' },
        layout: 'Asset Condition',
        children: [
          {
            path: '',
            name: 'inventory-asset-condition-list',
            component: () => import('@/views/settings/inventory/asset-condition/list/AssetConditionList.vue'),
            layout: 'Asset Condition List',
            meta: GENERAL_INVENTORY_META,
          },
          {
            path: 'trash',
            name: 'inventory-asset-condition-trash-list',
            component: () => import('@/views/settings/inventory/asset-condition/list/AssetConditionListTrash.vue'),
            layout: 'Asset Condition List',
            meta: GENERAL_INVENTORY_META,
          },
          {
            path: 'create',
            name: 'inventory-asset-condition-taxonomies',
            component: () => import('@/views/settings/inventory/asset-condition/create/AssetConditionCreate.vue'),
            layout: 'Asset Condition Create',
            meta: GENERAL_INVENTORY_META,
          },
          {
            path: ':id/update',
            name: 'inventory-asset-condition-update',
            component: () => import('@/views/settings/inventory/asset-condition/update/AssetConditionUpdate.vue'),
            layout: 'Asset Condition Update',
            meta: GENERAL_INVENTORY_META,
          },
        ],
      },
      {
        path: 'settings-asset-tags',
        name: 'settings-asset-tags',
        component: () => import('@/views/settings/inventory/asset-tags/AssetTags.vue'),
        redirect: { name: 'inventory-asset-tags-form' },
        layout: 'Asset Condition',
        children: [
          {
            path: '',
            name: 'inventory-asset-tags-form',
            component: () => import('@/views/settings/inventory/asset-tags/update/AssetTagsUpdate.vue'),
            layout: 'Asset Condition List',
            meta: GENERAL_INVENTORY_META,
          },
        ],
      },
      {
        path: 'settings-asset-general',
        name: 'settings-asset-general',
        component: () => import('@/views/settings/inventory/general/AssetGeneral.vue'),
        redirect: { name: 'inventory-asset-general-form' },
        layout: 'Asset General',
        children: [
          {
            path: '',
            name: 'inventory-asset-general-form',
            component: () => import('@/views/settings/inventory/general/update/AssetGeneralUpdate.vue'),
            layout: 'Asset General Update',
            meta: GENERAL_INVENTORY_META,
          },
        ],
      },
    ],
  },
]
