import { CATALOG_MAIN_META } from '@/router/meta/main/catalog'

export default {
  path: 'scheduled-transfer-order-by-asset/:id',
  name: 'scheduled-transfer-order-page-by-asset',
  alies: ['/:id'],
  component: () => import('@/views/main/orders/view/transfer/permanentTransferScheduled/permanentTransferByAssetId/PermanentTransferScheduledByAssetId.vue'),
  layout: 'Home Inventory Create Transfer By Asset',
  meta: CATALOG_MAIN_META,
}
