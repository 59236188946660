<template>
  <b-table-simple>
    <b-row class="checkboxGroup d-flex">
      <b-col
        style="max-width: max-content;"
        class="p-0"
      >
        <validation-provider
          #default="{ errors }"
          :name="$t(field.label || field.placeholder)"
          :rules="field.rules"
          :vid="name"
        >
          <div :class="`d-flex align-items-center flex-row-reverse ${isEditable ? 'check--disabled': ''}`">
            <b-form-checkbox
              :id="name"
              :ref="name"
              class="checkbox ml-auto"
              v-bind="getProps(field)"
              :checked="value"
              :disabled="isEditable"
              @input="$emit('input', $event)"
              @change="$emit('change', $event)"
            />
            <slot name="loading" />
          </div>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <b-col>
        <b-thead>
          <label
            :for="name"
            :class="`checkboxLabel ${isRequiredField ? 'font-weight-bolder':''}`"
          >
            {{ field.label }}
          </label>
        </b-thead>
      </b-col>
    </b-row>
  </b-table-simple>

</template>

<script>
import {
  BFormCheckbox, BCol, BRow, BThead, BTableSimple,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { inputFieldsVisibility } from '@/views/components/DynamicForm/mixins'

export default {
  name: 'LCheckboxLf',
  components: {
    BFormCheckbox,
    ValidationProvider,
    BThead,
    BCol,
    BRow,
    BTableSimple,
  },
  mixins: [inputFieldsVisibility],
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number, Boolean],
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isRequiredField: {
      type: Boolean,
      default: () => false,
    },
  },
  created() {
    if (!this.value) this.$emit('input', (this.field.options && this.field.options['unchecked-value'] ? this.field.options['unchecked-value'] : false))
  },
}
</script>

<style lang="scss">
[type=checkbox]:checked+label:before {
  background-color: transparent !important;
}
.check--disabled {
  .custom-control-input:disabled + label::after {
    background-color: #efefef;
    border-radius: 5px;
    opacity: 0.5;
  }
}

</style>
