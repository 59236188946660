import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import BSWarningModal from '@core/components/BSWarningModal/BSWarningModal.vue'
import BCoreSkeleton from '@core/components/b-skeleton/BSkeleton.vue'
import BTableSkeleton from '@core/components/b-table-skeleton/BTableSkeleton.vue'
import ComponentNotFound from '@core/components/component-not-found/ComponentNotFound.vue'
import FormFeaturesWrapper from '@core/components/FormFeaturesWrapper/FormFeaturesWrapper.vue'
import ConverterDate from '@core/components/converterDate/ConverterDate.vue'
import RenderList from '@core/components/renderList/RenderList.vue'
import IncrementDecrementInput from '@core/components/increment-decrement-input/IncrementDecrementInput.vue'
import { Can } from '@casl/vue'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(BSWarningModal.name, BSWarningModal)
Vue.component(BCoreSkeleton.name, BCoreSkeleton)
Vue.component(ComponentNotFound.name, ComponentNotFound)
Vue.component(BTableSkeleton.name, BTableSkeleton)
Vue.component(FormFeaturesWrapper.name, FormFeaturesWrapper)
Vue.component(ConverterDate.name, ConverterDate)
Vue.component(RenderList.name, RenderList)
Vue.component(IncrementDecrementInput.name, IncrementDecrementInput)
Vue.component('Can', Can)
