import userAudit from '@/router/settings/users/user-audit'
// eslint-disable-next-line import/named
import { GENERAL_USERS_META } from '../../meta/settings/users'

export default [
  {
    path: 'users',
    name: 'users',
    component: () => import('@/views/settings/users/Users.vue'),
    redirect: { name: 'settings-users-defaults' },
    children: [
      {
        path: 'defaults',
        name: 'settings-users-defaults',
        component: () => import('@/views/settings/users/default/Default.vue'),
        layout: 'Default',
        meta: GENERAL_USERS_META,
      },
      {
        path: 'user-list',
        name: 'user-list',
        component: () => import('@/views/settings/users/user-list/UserList.vue'),
        layout: 'Users List',
        meta: GENERAL_USERS_META,
      },
      {
        path: 'roles',
        name: 'settings-users-roles',
        layout: 'Roles',
        component: () => import('@/views/settings/users/roles/Roles.vue'),
        redirect: { name: 'settings-users-role-list' },
        children: [
          {
            path: '',
            name: 'settings-users-role-list',
            component: () => import('@/views/settings/users/roles/role-list/RoleList.vue'),
            layout: 'Role List',
            meta: GENERAL_USERS_META,
          },
          {
            path: 'trash',
            name: 'settings-users-role-trash-list',
            component: () => import('@/views/settings/users/roles/role-list/RoleListTashList.vue'),
            layout: 'Role List',
            meta: GENERAL_USERS_META,
          },
          {
            path: 'create',
            name: 'settings-users-role-create',
            component: () => import('@/views/settings/users/roles/role-create/RoleCreate.vue'),
            layout: 'Role Create',
            meta: GENERAL_USERS_META,
          },
          {
            path: ':id/update',
            name: 'settings-users-role-update',
            component: () => import('@/views/settings/users/roles/role-update/RoleUpdate.vue'),
            layout: 'Role Update',
            meta: GENERAL_USERS_META,
          },
        ],
      },
      {
        path: 'notifications',
        name: 'settings-users-notifications',
        layout: 'Notifications',
        component: () => import('@/views/settings/users/notifications/Notifications.vue'),
        redirect: { name: 'settings-users-notifications-list' },
        children: [
          {
            path: '',
            name: 'settings-users-notifications-list',
            component: () => import('@/views/settings/users/notifications/notifications-list/NotificationsList.vue'),
            layout: 'Notifications List',
            meta: GENERAL_USERS_META,
          },
          {
            path: 'trash',
            name: 'settings-users-notifications-trash-list',
            component: () => import('@/views/settings/users/notifications/notifications-list/NotificationsListTrashList.vue'),
            layout: 'Notifications List',
            meta: GENERAL_USERS_META,
          },
          {
            path: 'create',
            name: 'settings-users-notifications-create',
            component: () => import('@/views/settings/users/notifications/notifications-create/NotificationsCreate.vue'),
            layout: 'Notifications Create',
            meta: GENERAL_USERS_META,
          },
          {
            path: ':id/update',
            name: 'settings-users-notifications-update',
            component: () => import('@/views/settings/users/notifications/notifications-update/NotificationsUpdate.vue'),
            layout: 'Notifications Update',
            meta: GENERAL_USERS_META,
          },
        ],
      },
      {
        path: 'trash',
        name: 'user-trash-list',
        component: () => import('@/views/settings/users/user-list/UserTrashList.vue'),
        layout: 'Users Trash List',
        meta: GENERAL_USERS_META,
      },
      {
        path: 'create',
        name: 'user-create',
        component: () => import('@/views/settings/users/user-create/UserCreate.vue'),
        layout: 'Users Create',
        meta: GENERAL_USERS_META,
      },
      {
        path: ':id',
        name: 'get-user',
        component: () => import('@/views/settings/users/user/User.vue'),
        layout: 'Get User',
        redirect: { name: 'user-audit' },
        children: [
          {
            path: 'update',
            name: 'user-update',
            component: () => import('@/views/settings/users/user-update/UserUpdate.vue'),
            layout: 'Users Update',
            meta: GENERAL_USERS_META,
          },
          userAudit,
        ],
      },
    ],
  },
]
