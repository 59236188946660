import { INVENTORY_MAIN_META } from '../../meta/main/inventory'

export default [
  {
    path: 'inventory',
    name: 'home-inventory',
    component: () => import('@/views/main/inventory/Inventory.vue'),
    redirect: { name: 'home-inventory-list-catalog' },
    children: [
      {
        path: 'catalog',
        name: 'home-inventory-list-catalog',
        component: () => import('@/views/main/inventory/inventory-list/InventoryListCatalog.vue'),
        layout: 'Home Inventory',
        meta: INVENTORY_MAIN_META,
      },
      {
        path: 'catalog-audit/:id/audit',
        name: 'home-inventory-catalog-item-audit',
        component: () => import('@/views/main/inventory/inventory-view/catalog-audit/CatalogAudit.vue'),
        layout: 'Home Catalog Audit Update',
        meta: INVENTORY_MAIN_META,
      },
      {
        path: 'catalog-audit/:id/audit/:inv_id',
        name: 'home-inventory-catalog-item-audit-inv',
        component: () => import('@/views/main/inventory/inventory-view/catalog-audit/CatalogAudit.vue'),
        layout: 'Home Catalog Audit Update Inventory',
        meta: INVENTORY_MAIN_META,
      },
      {
        path: 'assets',
        name: 'home-inventory-list-assets',
        component: () => import('@/views/main/inventory/inventory-list/InventoryListAssets.vue'),
        layout: 'Home Inventory',
        meta: INVENTORY_MAIN_META,
      },
      {
        path: 'calendar/:id',
        name: 'home-inventory-calendar',
        component: () => import('@/views/main/inventory/inventory-calendar-product/InventoryCalendarView.vue'),
        layout: 'Home Inventory',
        redirect: { name: 'home-inventory-table' },
        meta: INVENTORY_MAIN_META,
        children: [
          {
            path: '',
            name: 'home-inventory-table',
            component: () => import('@/views/main/inventory/inventory-calendar-product/InventoryTable.vue'),
            layout: 'Home Inventory',
            meta: INVENTORY_MAIN_META,
          },
        ],
      },
      {
        path: 'inventory-view/:id',
        name: 'home-inventory-view',
        component: () => import('@/views/main/inventory/inventory-view/InventoryView.vue'),
        layout: 'Home Inventory View List',
        redirect: { name: 'home-inventory-view' },
        children: [
          {
            path: '',
            name: 'home-inventory-view',
            component: () => import('@/views/main/inventory/inventory-view/InventoryViewList.vue'),
            layout: 'Home Inventory View List',
            meta: INVENTORY_MAIN_META,
          },
          {
            path: 'quote',
            name: 'home-inventory-item-quote',
            component: () => import('@/views/main/inventory/inventory-view/quote/Quote.vue'),
            layout: 'Home Inventory View List',
            redirect: { name: 'home-inventory-item-quote-view' },
            children: [
              {
                path: '',
                name: 'home-inventory-item-quote-view',
                component: () => import('@/views/main/inventory/inventory-view/quote/QuoteView.vue'),
                layout: 'Home Inventory',
                meta: INVENTORY_MAIN_META,
              },
            ],
          },
          {
            path: 'create',
            name: 'home-inventory-item-create',
            component: () => import('@/views/main/inventory/inventory-view/create/InventoryCreate.vue'),
            layout: 'Home Inventory View List',
            meta: INVENTORY_MAIN_META,
          },
          {
            path: 'update/:inv_id',
            name: 'home-inventory-item-update',
            component: () => import('@/views/main/inventory/inventory-view/update/InventoryUpdate.vue'),
            layout: 'Home Inventory Update',
            meta: INVENTORY_MAIN_META,
          },
          {
            path: 'trash-list',
            name: 'home-inventory-item-trash-list',
            component: () => import('@/views/main/inventory/inventory-view/InventoryTrashList.vue'),
            layout: 'Home Inventory Update',
            meta: INVENTORY_MAIN_META,
          },
        ],
      },
      {
        path: 'inventory/:id/audit/:inv_id',
        name: 'home-inventory-item-audit',
        component: () => import('@/views/main/inventory/inventory-view/audit/Audit.vue'),
        layout: 'Home Inventory Update',
        meta: INVENTORY_MAIN_META,
      },
    ],
  },
]
