export default function mappingFields(fields, data, mapping = {}) {
  const newData = {}
  fields.forEach(field => {
    if (data[field] !== null && data[field] !== undefined) newData[field] = data[field]
  })
  Object.entries(mapping).forEach(([field, value]) => {
    newData[field] = value
  })
  return newData
}
