<template>
  <div>
    <b-skeleton
      v-if="!formattedDate"
      width="45%"
      style="margin-bottom: 0"
    />
    <div
      v-else
      :class="`${formattedDate === formattedDateInvalid ? 'text-danger' : '' }`"
    >
      {{ formattedDate }}
    </div>
  </div>
</template>

<script>
import { BSkeleton } from 'bootstrap-vue'

export default {
  name: 'ConverterDate',
  components: {
    BSkeleton,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formattedDate: null,
      formattedDateInvalid: this.$t('Invalid Date'),
    }
  },
  computed: {
    settingsDateFormat() {
      return this.$store.state.listModule.settingsDateFormat
    },
  },
  watch: {
    settingsDateFormat: {
      handler() {
        this.formatDate()
      },
      immediate: true,
    },
  },
  methods: {
    isDate(date) {
      // eslint-disable-next-line no-restricted-globals
      return (new Date(date) !== 'Invalid Date') && !isNaN(new Date(date))
    },
    formatDate() {
      if (!this.isDate(this.date)) {
        this.formattedDate = this.$t('Invalid Date')
      } else {
        const generatedDate = []
        const FORMAT = [{
          label: 'd',
          method: 'getDate',
        }, {
          label: 'm',
          method: 'getMonth',
        }, {
          label: 'y',
          method: 'getFullYear',
        }]

        const timeZone = this.$store.state.app.timezone
        const dateInTimeZones = new Date((typeof this.date === 'string' ? new Date(this.date) : this.date).toLocaleString('en-US', { timeZone }))
        const dateFormatData = this.settingsDateFormat

        const dateFormatLabel = dateFormatData?.label?.split('/')

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < dateFormatLabel?.length; i++) {
          const formatToLowerCase = dateFormatLabel?.[i].toLowerCase()
            .split('')[0]

          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < FORMAT.length; j++) {
            if (formatToLowerCase.includes(FORMAT[j]?.label)) {
              if (FORMAT[j].method === 'getMonth') {
                generatedDate.push(new Date(dateInTimeZones)[FORMAT[j].method]() + 1)
              } else {
                generatedDate.push(new Date(dateInTimeZones)[FORMAT[j].method]())
              }
            }
          }
        }
        this.formattedDate = `${generatedDate.join('/')} ${new Date(dateInTimeZones).toLocaleTimeString('en-US', { timeStyle: 'medium' })}`
      }
    },
  },
}
</script>
