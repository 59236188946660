import { CATALOG_MAIN_META } from '@/router/meta/main/catalog'

export default {
  path: 'rental-sales',
  name: 'home-orders-rental-sales',
  layout: 'Warehouses',
  component: () => import('@/views/main/orders/view/rental-sales/RentalSales.vue'),
  redirect: { name: 'home-orders-rental-sales-list' },
  children: [
    {
      path: '',
      name: 'home-orders-rental-sales-list',
      component: () => import('@/views/main/orders/view/rental-sales/list/List.vue'),
      layout: 'Home Orders View',
      meta: CATALOG_MAIN_META,
    },
    {
      path: 'create',
      name: 'home-orders-rental-sales-create',
      component: () => import('@/views/main/orders/view/rental-sales/create/RentalSalesCreate.vue'),
      layout: 'Create Rental Sales Form',
      meta: CATALOG_MAIN_META,
    },
    {
      path: ':id',
      name: 'home-orders-rental-sales-view',
      component: () => import('@/views/main/orders/view/rental-sales/update/QuoteUpdate.vue'),
      layout: 'View Rental Sales Form',
      redirect: { name: 'home-orders-rental-sales-create' },
      children: [
        {
          path: 'draft',
          name: 'draft-quote-information',
          component: () => import('@/views/main/orders/view/rental-sales/update/pages/draft/index.vue'),
          layout: 'Draft Order',
          meta: CATALOG_MAIN_META,
        },
        {
          path: 'hold',
          name: 'hold-quote-information',
          component: () => import('@/views/main/orders/view/rental-sales/update/pages/hold/index.vue'),
          layout: 'Hold Order',
          meta: CATALOG_MAIN_META,
        },
        {
          path: 'approve',
          name: 'approve-quote-information',
          component: () => import('@/views/main/orders/view/rental-sales/update/pages/draft/index.vue'),
          layout: 'Approve Order',
          meta: CATALOG_MAIN_META,
        },
        {
          path: 'payment',
          name: 'payment-quote-information',
          component: () => import('@/views/main/orders/view/rental-sales/update/pages/payment/index.vue'),
          layout: 'Payment Order',
          meta: CATALOG_MAIN_META,
        },
      ],
    },
  ],
}
