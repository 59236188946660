import pricing from '@/router/settings/catalog/pricing'
import { CATALOG_MAIN_META } from '../../meta/settings/catalog'

export default [
  {
    path: 'catalog',
    name: 'settings-catalog',
    component: () => import('@/views/settings/catalog/Catalog.vue'),
    redirect: { name: 'settings-catalog-defaults' },
    children: [
      pricing,
      {
        path: 'defaults',
        name: 'settings-catalog-defaults',
        component: () => import('@/views/settings/catalog/defaults/Defaults.vue'),
        layout: 'Catalog Defaults',
        meta: CATALOG_MAIN_META,
      },
      {
        path: 'skus',
        name: 'settings-catalog-skus',
        component: () => import('@/views/settings/catalog/skus/SKUs.vue'),
        layout: 'Catalog',
        meta: CATALOG_MAIN_META,
      },
      {
        path: 'kits',
        name: 'settings-catalog-kits',
        component: () => import('@/views/settings/catalog/kits/Kits.vue'),
        layout: 'Catalog',
        meta: CATALOG_MAIN_META,
      },
      {
        path: 'brands',
        name: 'settings-catalog-brands',
        component: () => import('@/views/settings/catalog/brands/Brands.vue'),
        redirect: { name: 'settings-catalog-brand-list' },
        layout: 'Catalog',
        children: [
          {
            path: '',
            name: 'settings-catalog-brand-list',
            component: () => import('@/views/settings/catalog/brands/list/BrandList.vue'),
            layout: 'Brands List',
            meta: CATALOG_MAIN_META,
          },
          {
            path: 'trash',
            name: 'settings-catalog-brand-trash-list',
            component: () => import('@/views/settings/catalog/brands/list/BrandListTrash.vue'),
            layout: 'Brands List',
            meta: CATALOG_MAIN_META,
          },
          {
            path: 'create',
            name: 'settings-catalog-brand-create',
            component: () => import('@/views/settings/catalog/brands/create/BrandsCreate.vue'),
            layout: 'Brand Create',
            meta: CATALOG_MAIN_META,
          },
          {
            path: ':id/update',
            name: 'settings-catalog-brand-update',
            component: () => import('@/views/settings/catalog/brands/update/BrandUpdate.vue'),
            layout: 'Brand Update',
            meta: CATALOG_MAIN_META,
          },
          {
            path: ':id/brand-information',
            name: 'settings-catalog-brand-information',
            component: () => import('@/views/settings/catalog/brands/components/BrandInformation.vue'),
            layout: 'Brand Information',
            meta: CATALOG_MAIN_META,
          },
          {
            path: ':id/products-in-this-brand',
            name: 'settings-brands-products-list',
            component: () => import('@/views/settings/catalog/brands/list/ProductsListFilteredByBrand.vue'),
            layout: 'Products List Filtered by Brand',
            meta: CATALOG_MAIN_META,
          },
        ],
      },
      {
        path: 'categories',
        name: 'settings-catalog-categories',
        component: () => import('@/views/settings/catalog/categories/Categories.vue'),
        layout: 'Catalog Categories',
        redirect: { name: 'settings-categories-list' },
        children: [
          {
            path: '',
            name: 'settings-categories-list',
            component: () => import('@/views/settings/catalog/categories/list/CategoriesList.vue'),
            layout: 'Catalog Categories List',
            meta: CATALOG_MAIN_META,
          },
          {
            path: 'trash',
            name: 'settings-categories-trash-list',
            component: () => import('@/views/settings/catalog/categories/list/CategoriesTrashList.vue'),
            layout: 'Catalog Categories Trash List',
            meta: CATALOG_MAIN_META,
          },
          {
            path: 'create',
            name: 'settings-categories-create',
            component: () => import('@/views/settings/catalog/categories/create/CategoriesCreate.vue'),
            layout: 'Catalog Category Create',
            meta: CATALOG_MAIN_META,
          },
          {
            path: ':id/update',
            name: 'settings-categories-update',
            component: () => import('@/views/settings/catalog/categories/update/CategoriesUpdate.vue'),
            layout: 'Catalog Category Update',
            meta: CATALOG_MAIN_META,
          },
          {
            path: ':id/products-in-this-category',
            name: 'settings-categories-products-list',
            component: () => import('@/views/settings/catalog/categories/list/ProductsListFilteredByCategory.vue'),
            layout: 'Products List Filtered by Category',
            meta: CATALOG_MAIN_META,
          },
        ],
      },
    ],
  },
]
