import { CATALOG_MAIN_META } from '@/router/meta/main/catalog'

export default {
  path: 'rental-sales-v2',
  name: 'home-orders-rental-sales-v2',
  layout: 'Warehouses',
  component: () => import('@/views/main/orders/view/new-rental-sales/RentalSales.vue'),
  redirect: { name: 'home-orders-rental-sales-list-v2' },
  children: [
    {
      path: '',
      name: 'home-orders-rental-sales-list-v2',
      component: () => import('@/views/main/orders/view/new-rental-sales/list/List.vue'),
      layout: 'Home Orders View',
      meta: CATALOG_MAIN_META,
    },
    {
      path: '',
      name: 'home-orders-rental-sales-create-quote-v2',
      component: () => import('@/views/main/orders/view/new-rental-sales/create/Create.vue'),
      layout: 'Home Orders View',
      redirect: { name: 'home-orders-rental-sales-create-v2' },
      children: [
        {
          path: 'create-rental-sales-quote',
          name: 'home-orders-rental-sales-create-v2',
          component: () => import('@/views/main/orders/view/new-rental-sales/create/RentalSalesCreate.vue'),
          layout: 'Home Orders View',
          meta: CATALOG_MAIN_META,
        },
      ],
    },
    {
      path: ':id',
      name: 'home-orders-rental-sales-update-quote-v2',
      component: () => import('@/views/main/orders/view/new-rental-sales/update/Update.vue'),
      layout: 'Home Orders View',
      children: [
        {
          path: 'draft',
          name: 'draft-quote-information-rental-update-v2',
          component: () => import('@/views/main/orders/view/new-rental-sales/update/QuoteUpdate.vue'),
          layout: 'Draft Order',
          meta: CATALOG_MAIN_META,
        },
        // {
        //   path: 'approve/:id',
        //   name: 'approve-quote-information',
        //   component: () => import('@/views/main/orders/view/new-rental-sales/draft/RentalSalesDraft.vue'),
        //   layout: 'Approve Order',
        //   meta: CATALOG_MAIN_META,
        // },
        {
          path: 'hold-quote-information/:id',
          name: 'hold-quote-information-v2',
          component: () => import('@/views/main/orders/view/new-rental-sales/update/QuoteUpdate.vue'),
          layout: 'Qoute',
          meta: CATALOG_MAIN_META,
        },

        // {
        //   path: 'payment/:id',
        //   name: 'payment-quote-information',
        //   component: () => import('@/views/main/orders/view/new-rental-sales/payment/RentalSalePayment.vue'),
        //   layout: 'Payment Order',
        //   meta: CATALOG_MAIN_META,
        // },
      ],
    },
  ],
}
