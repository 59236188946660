import { CATALOG_MAIN_META } from '@/router/meta/main/catalog'

export default {
  path: 'credit-note',
  name: 'home-orders-credit-note',
  layout: 'Warehouses',
  component: () => import('@/views/main/orders/view/credit-note/CreditNote.vue'),
  redirect: { name: 'home-orders-credit-note-list' },
  children: [
    {
      path: '',
      name: 'home-orders-credit-note-list',
      component: () => import('@/views/main/orders/view/credit-note/list/List.vue'),
      layout: 'Home Orders View',
      meta: CATALOG_MAIN_META,
    },
    {
      path: 'create',
      name: 'home-orders-credit-note-create-quote',
      component: () => import('@/views/main/orders/view/credit-note/create/Create.vue'),
      layout: 'Home Orders View',
      meta: CATALOG_MAIN_META,
    },
    {
      path: 'credit-note-update-quote/:id',
      name: 'credit-note-update-quote',
      component: () => import('@/views/main/orders/view/credit-note/update/Update.vue'),
      layout: 'Home Orders View',
      meta: CATALOG_MAIN_META,
    },
    {
      path: 'credit-note-paid-quote/:id',
      name: 'credit-note-paid-quote',
      component: () => import('@/views/main/orders/view/credit-note/paid/Paid.vue'),
      layout: 'Home Orders View',
      meta: CATALOG_MAIN_META,
    },
  ],
}
