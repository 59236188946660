<template>
  <button
    v-bind="$attrs"
    class="dashedBtn"
    type="button"
    :disabled="disabled"
    v-on="$listeners"
  >
    <slot />
  </button>
</template>

<script>

export default {
  name: 'ButtonDashed',
  props: {
    disabled: {
      type: Boolean,
      default: false,
      request: false,
    },
  },
}
</script>
