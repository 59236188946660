<template>
  <b-form-group
    :label-class="checkRequired(field) ? 'inputLabel': 'inputLabelNormal'"
    :label="$t(field.label)"
    :label-for="name"
  >
    <validation-provider
      #default="{ validate, errors }"
      :name="$t(field.label || field.placeholder)"
      :rules="isValid ? field.rules : addPhoneValidRule(field.rules)"
      :vid="name"
    >
      <div
        class="d-flex align-items-center"
      >
        <vue-phone-number-input
          :id="name"
          class="w-100"
          v-bind="getProps(field)"
          :placeholder="getPlaceholder(field)"
          :value="value"
          :disabled="isEditable"
          :default-country-code="defaultCountryCode"
          @update="update"
          @input="$emit('input', $event)"
          @blur="$emit('blur', $event)"
        />
        <slot name="input-extra-el" />
      </div>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { inputFieldsVisibility } from '@/views/components/DynamicForm/mixins'
import VuePhoneNumberInput from 'vue-phone-number-input'
import { phoneIsValid } from '@/libs/validations/validations'

export default {
  name: 'LPhoneMaskInput',
  components: {
    BFormGroup,
    ValidationProvider,
    VuePhoneNumberInput,
  },
  mixins: [inputFieldsVisibility],
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    defaultCountryCode: {
      type: String,
      required: false,
      default: 'US',
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phone: '',
      isValid: false,
      phoneIsValid,
    }
  },
  methods: {
    update(val) {
      if (val) this.isValid = val.isValid
      // eslint-disable-next-line no-param-reassign
      delete val.phoneNumber

      this.$emit('input-obj', val)
    },
    addPhoneValidRule(rules) {
      if (!rules) return 'phone-is-valid'
      return `${rules}|phone-is-valid`
    },
  },
}
</script>

<style>
.vti__dropdown-list {
  z-index: 10;
}
</style>
