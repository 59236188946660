import { CUSTOMER_MAIN_META } from '../../meta/main/customers'

export default {
  path: 'addresses',
  name: 'home-customers-addresses',
  component: () => import('@/views/main/customers/view/address/Address.vue'),
  layout: 'Addresses',
  redirect: { name: 'home-customers-addresses-list' },
  children: [
    {
      path: '',
      name: 'home-customers-addresses-list',
      component: () => import('@/views/main/customers/view/address/list/AddressList.vue'),
      layout: 'Customer Address List',
      meta: CUSTOMER_MAIN_META,
    },
    {
      path: 'trash',
      name: 'home-customers-addresses-trash',
      component: () => import('@/views/main/customers/view/address/list/AddressTrashList.vue'),
      layout: 'Customer Addresses To Trash',
      meta: CUSTOMER_MAIN_META,
    },
    {
      path: 'create',
      name: 'home-customers-addresses-create',
      component: () => import('@/views/main/customers/view/address/create/AddressCreate.vue'),
      layout: 'Customer Address Create',
      meta: CUSTOMER_MAIN_META,
    },
    {
      path: ':addressId',
      name: 'home-customers-addresses-update-id',
      component: () => import('@/views/main/customers/view/address/update/UpdateAddress.vue'),
      layout: 'Customer Address Update',
      redirect: { name: 'home-customers-addresses-update' },
      children: [
        {
          path: 'update',
          name: 'home-customers-addresses-update',
          component: () => import('@/views/main/customers/view/address/update/UpdateAddress.vue'),
          layout: 'Customer Address Update',
          meta: CUSTOMER_MAIN_META,
        },
      ],
    },
  ],
}
