// eslint-disable-next-line import/named
import { GENERAL_USERS_META } from '../../meta/settings/users'

export default {
  path: 'audit',
  name: 'user-audit',
  component: () => import('@/views/settings/users/user-audit/UserAudit.vue'),
  layout: 'User Audit',
  redirect: { name: 'user-audit-list' },
  children: [
    {
      path: '',
      name: 'user-audit-list',
      component: () => import('@/views/settings/users/user-audit/audit-list/AuditList.vue'),
      layout: 'User Audit List',
      meta: GENERAL_USERS_META,
    },
  ],
}
