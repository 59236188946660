<template>
  <b-row class="checkboxGroup">
    <b-form-group
      :label-class="checkRequired(field) ? 'inputLabel pl-0': 'inputLabelNormal'"
      :label="isBFormGroup ? $t('Status') : '' "
      :label-for="name"
      class="w-100 pl-0"
    >
      <validation-provider
        #default="{ errors }"
        :name="$t(field.label || field.placeholder)"
        :rules="field.rules"
        :vid="name"
      >
        <div class="d-flex w-100">
          <b-col
            md="1"
            sm="1"
            :class="`${isEditable ? 'check--disabled': ''}`"
          >
            <b-form-checkbox
              :id="name"
              :ref="name"
              class="checkbox ml-auto"
              v-bind="getProps(field)"
              :checked="getValue()"
              :disabled="isEditable"
              @input="$emit('input', $event)"
            />
          </b-col>
          <b-col
            md="11"
            sm="11"
          >
            <label
              style="margin: 5px 0 5px 0;"
              :for="name"
            >{{ $t(field.label ? field.label : 'Active') }}</label>
          </b-col>
        </div>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </b-row>

</template>

<script>
import {
  BFormCheckbox, BCol, BRow, BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { inputFieldsVisibility } from '@/views/components/DynamicForm/mixins'
import { isNull } from '@core/components/b-table-responsive/table-responsive/table/utils/inspect'

export default {
  name: 'LActiveCheckLfBox',
  components: {
    BFormCheckbox,
    ValidationProvider,
    BFormGroup,
    BCol,
    BRow,
  },
  mixins: [inputFieldsVisibility],
  props: {
    field: {
      type: Object,
      required: true,
    },
    defaultValue: {
      type: [String, Number, Boolean],
      default: null,
    },
    value: {
      type: [String, Number, Boolean],
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isBFormGroup: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    if (!this.value) this.$emit('input', (this.field.options && this.field.options['unchecked-value'] ? this.field.options['unchecked-value'] : false))
  },
  methods: {
    getValue() {
      return !isNull(this.defaultValue) ? this.defaultValue : this.value
    },
  },
}
</script>

<style lang="scss">
[type=checkbox]:checked+label:before {
  background-color: transparent !important;
}
.check--disabled {
  .custom-control-input:disabled + label::after {
    background-color: #efefef;
    border-radius: 5px;
    opacity: 0.5;
  }
}

</style>
