export default {
  SET_CUSTOMER_ADDRESS_FORM_CLONE(state, data) {
    state.customerAddressForm = JSON.stringify(data)
  },
  SET_ACCOUNT_HOLDER_CLONE(state, data) {
    state.accountHolderContactForm = JSON.stringify(data)
  },
  SET_ACCOUNT_ADDRESS_CLONE(state, data) {
    state.accountAddressForm = JSON.stringify(data)
  },
  SET_CUSTOMER_CONTACT_FORM_CLONE(state, data) {
    state.customerContactsForm = JSON.stringify(data)
  },
  SET_CUSTOMER_VERIFICATION_FORM_CLONE(state, data) {
    state.customerVerificationFormClone = JSON.stringify(data)
  },
  SET_SETTING_USER_FORM_CLONE(state, data) {
    state.settingUserForm = JSON.stringify(data)
  },
  SET_SETTING_ROLE_FORM_CLONE(state, data) {
    state.role = JSON.stringify(data)
  },
  SET_SETTING_NOTIFICATION_FORM_CLONE(state, data) {
    state.notification = JSON.stringify(data)
  },
  SET_SETTING_WAREHOUSE_FORM_CLONE(state, data) {
    state.settingWarehouse = JSON.stringify(data)
  },
  SET_SETTING_BRAND_FORM_CLONE(state, data) {
    state.settingBrand = JSON.stringify(data)
  },
  SET_SETTING_PAYMENT_TERM_FORM_CLONE(state, data) {
    state.settingPaymentTerm = JSON.stringify(data)
  },
  SET_SETTING_CATEGORY_FORM_CLONE(state, data) {
    state.settingCategoryForm = JSON.stringify(data)
  },
  SET_SETTING_CUSTOMER_DEFAULT_FORM_CLONE(state, data) {
    state.settingCustomerDefaultsForm = JSON.stringify(data)
  },
  SET_SETTING_CATALOG_DEFAULT_FORM_CLONE(state, data) {
    state.settingsCatalogDefaultsForm = JSON.stringify(data)
  },
  SET_SETTING_USER_DEFAULT_FORM_CLONE(state, data) {
    state.settingsUserDefaultsForm = JSON.stringify(data)
  },
  SET_RATES_FORM_CLONE(state, data) {
    state.catalogRatesForm = JSON.stringify(data)
  },
  SET_CATALOG_COMPONENT_DEFAULT_FORM_CLONE(state, data) {
    state.catalogComponentDefaultsForm = JSON.stringify(data)
  },
  SET_SETTING_PRICING_TIERS_FORM_CLONE(state, data) {
    state.settingCatalogPriceTiersForm = JSON.stringify(data)
  },
  SET_CUSTOMER_FORM_CLONE(state, data) {
    state.customerGeneralForm = JSON.stringify(data)
  },
  SET_CATALOG_FORM_CLONE(state, data) {
    state.catalogFormClone = JSON.stringify(data)
  },
  SET_SETTING_PRICING_RULES_FORM_CLONE(state, data) {
    state.settingCatalogPricingRulesForm = JSON.stringify(data)
  },
  SET_SETTING_CATALOG_PRICING(state, data) {
    state.settingCatalogPricingClone = JSON.stringify(data)
  },
  SET_SETTING_DISCOUNTS_FORM_CLONE(state, data) {
    state.settingCatalogDiscountsForm = JSON.stringify(data)
  },
  SET_SETTING_PAYMENT_METHODS_FORM_CLONE(state, data) {
    state.paymentMethodsForm = JSON.stringify(data)
  },
  OPERATION_HOURS_FORM_CLONE_FORM_CLONE(state, data) {
    state.operationHoursFormClone = JSON.stringify(data)
  },
  SET_CATALOG_PRODUCT_SUBSTITUTES_CLONE(state, data) {
    state.productSubstitutesClone = JSON.stringify(data)
  },
  SET_CATALOG_PRODUCT_PROCUREMENTS_CLONE(state, data) {
    state.productProcurementsClone = JSON.stringify(data)
  },
  SET_SETTING_FULFILLMENT_PROCESSES_FORM_CLONE(state, data) {
    state.fulfillmentFormClone = JSON.stringify(data)
  },
  SET_KIT_FORM_CLONE(state, data) {
    state.kitFormClone = JSON.stringify(data)
  },
  SET_PHY_PROPERTIES_FORM_CLONE(state, data) {
    state.phyPropertiesFormClone = JSON.stringify(data)
  },
  SET_CATALOG_COMPONENT_FORM_CLONE(state, data) {
    state.catalogComponentForm = JSON.stringify(data)
  },
  SET_TEMPLATE_FORM_CLONE(state, data) {
    state.templateFormClone = JSON.stringify(data)
  },
  SET_LABEL_FORM_CLONE(state, data) {
    state.settingFulfillmentLabelFormClone = data
  },
  SET_SETTING_FULFILLMENT_RULES_FORM_CLONE(state, data) {
    state.settingFulfillmentRulesFormClone = data
  },
  SET_INVENTORY_FORM_CLONE(state, data) {
    state.inventoryFormClone = JSON.stringify(data)
  },
  SET_SETTING_ASSET_CONDITION_FORM_CLONE(state, data) {
    state.assetConditionFormClone = JSON.stringify(data)
  },
  SET_RENTAL_SALES_FORM_CLONE(state, data) {
    state.rentalSalesFormClone = JSON.stringify(data)
  },
  SET_PURCHASE_FORM_CLONE(state, data) {
    state.purchaseFormClone = JSON.stringify(data)
  },
  SET_SETTING_TAX_FORM_CLONE(state, data) {
    state.taxFormClone = JSON.stringify(data)
  },
  SET_ASSET_TAGS_FORM_CLONE(state, data) {
    state.assetTagsFormClone = data
  },
  SET_ASSET_GENERAL_FORM_CLONE(state, data) {
    state.assetGeneralFormClone = data
  },
  SET_SETTING_DISPATCH_FORM_CLONE(state, data) {
    state.settingsDispatchFormClone = JSON.stringify(data)
  },
  SET_ORDER_TRANSFER_ORDERS(state, data) {
    state.settingsTransferOrdersFormClone = JSON.stringify(data)
  },
  SET_ORDER_SERVICE_ORDERS_CLONE(state, data) {
    state.settingsServiceOrdersFormClone = JSON.stringify(data)
  },
  SET_CREDIT_NOTES_ORDERS_CLONE(state, data) {
    state.settingsCreditNotesFormClone = JSON.stringify(data)
  },
  SET_SUB_RENTAL_ORDERS_CLONE(state, data) {
    state.settingsSubRentalFormClone = JSON.stringify(data)
  },
  SET_ORDER_RENTAL_ORDERS_CLONE(state, data) {
    state.settingsRentalOrdersFormClone = JSON.stringify(data)
  },
  SET_PURCHASE_SALES_FORM_CLONE(state, data) {
    state.settingsPurchaseOrdersFormClone = JSON.stringify(data)
  },
  SET_TRANSFER_ORDER_BY_CLONE(state, data) {
    state.transferOrderClone = JSON.stringify(data)
  },
  SET_TRANSFER_ORDER_SCHEDULED_INFORMATION_CLONE(state, data) {
    state.transferOrderInformationClone = JSON.stringify(data)
  },
  SET_CARDKNOX_FORM_CLONE(state, data) {
    state.cardknoxFormClone = JSON.stringify(data)
  },
}
