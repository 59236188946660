import Vue from 'vue'

import LSelect from './LSelect.vue'
import LCheckbox from './LCheckbox.vue'
import LCheckboxLf from './LCheckboxLf.vue'
import LTextarea from './LTextarea.vue'
import LTextInput from './LTextInput.vue'
import LMaskInput from './LMaskInput.vue'
import LDataPicker from './LDataPicker.vue'
import LFlatPicker from './LFlatPicker.vue'
import LRadioGroup from './LRadioGroup.vue'
import LSelectChild from './LSelectChild.vue'
import LNumericInput from './LNumericInput.vue'
import WysiwygEditor from './WysiwygEditor.vue'
import LPasswordInput from './LPasswordInput.vue'
import LActiveCheckBox from './LActiveCheckbox.vue'
import LActiveCheckLfBox from './LActiveCheckLfbox.vue'
import LTagsInput from './LTagsInput/LTagsInput.vue'
import LCurrencyMaskInput from './LCurrencyMaskInput.vue'
import LTextInputValidator from './LTextInputValidator.vue'
import LPhoneMaskInput from './LPhoneMaskInput.vue'
import LDateBirthMaskInput from './LDateBirthMaskInput.vue'
import LTimePickerInput from './LTimePickerInput.vue'
import LTagInput from './LTagsInput/LTagInput.vue'

Vue.component('LSelect', LSelect)
Vue.component('LCheckbox', LCheckbox)
Vue.component('LCheckboxLf', LCheckboxLf)
Vue.component('LTextarea', LTextarea)
Vue.component('LTextInput', LTextInput)
Vue.component('LTagsInput', LTagsInput)
Vue.component('LTagInput', LTagInput)
Vue.component('LMaskInput', LMaskInput)
Vue.component('LFlatPicker', LFlatPicker)
Vue.component('LRadioGroup', LRadioGroup)
Vue.component('LDataPicker', LDataPicker)
Vue.component('LSelectChild', LSelectChild)
Vue.component('LNumericInput', LNumericInput)
Vue.component('WysiwygEditor', WysiwygEditor)
Vue.component('LPasswordInput', LPasswordInput)
Vue.component('LPhoneMaskInput', LPhoneMaskInput)
Vue.component('LActiveCheckBox', LActiveCheckBox)
Vue.component('LActiveCheckLfBox', LActiveCheckLfBox)
Vue.component('LCurrencyMaskInput', LCurrencyMaskInput)
Vue.component('LTextInputValidator', LTextInputValidator)
Vue.component('LDateBirthMaskInput', LDateBirthMaskInput)
Vue.component('LTimePickerInput', LTimePickerInput)
