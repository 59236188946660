export default {
  // Endpoints
  loginEndpoint: '/login',
  registerEndpoint: '/register',
  logoutEndpoint: '/logout',
  userDataEndpoint: '/auth/user/me',
  confirmEmailEndpoint: '/confirm-mail',
  forgotPasswordEndpoint: '/password/forgot',
  restorePasswordEndpoint: '/password/reset',
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'token',
  storageUserDataKeyName: 'userData',
  storageFormChangeKeyName: 'storageFormChange',
  storageOrganizationPrimaryThemeColor: 'storageThemeColor',
  storageOrganizationRequiresEditConfirmationKeyName: 'organizationRequiresEditConfirmation',
  storageOrganizationDateFormat: 'organizationDateFormat',
  settingCustomerDefaultFormKeyName: 'settingCustomerDefaultForm',
  storageUserPermissionKeyName: 'userPermissionList',
  userPermissionsLastModifiedKeyName: 'userPermissionsLastModified',

  // keyCloak
  keyCloakRedirectUrl: `${window.location.origin}/keycloak`,
  keyCloakAuthUrl: '/keycloak/auth-url',
  keyCloakLoginUrl: '/keycloak/login',

}
